// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-section {
    display: flex;
    justify-content: space-between;
  }
  
  .filter-item {
    margin-bottom: 10px;
  }
  
  .filter-item label {
    display: block;
    margin-bottom: 5px;
  }
  
  .filter-item input,
  .filter-item select {
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Estilo adicional conforme necessário */
  `, "",{"version":3,"sources":["webpack://./src/views/styles/filter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;;IAEE,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA,yCAAyC","sourcesContent":[".filter-section {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .filter-item {\n    margin-bottom: 10px;\n  }\n  \n  .filter-item label {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  .filter-item input,\n  .filter-item select {\n    width: 200px;\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  /* Estilo adicional conforme necessário */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
