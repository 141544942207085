import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'; // Importe jsPDF

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 24,
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { flexDirection: 'row' },
  tableCol: { width: '25%', borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
  tableCell: { marginTop: 5, fontSize: 12, textAlign: 'center' },
});

const generatePDF = (cambistas) => {
  const input = document.getElementById('pdf-content');
  
  if (!input) {
    console.error('Element with id "pdf-content" not found.');
    return;
  }

  html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('download.pdf');
    })
    .catch((error) => {
      console.error('Error generating PDF:', error);
    });
};

const PdfGeral = ({ cambistas }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Relatório de Cambistas</Text>
          <View style={styles.table} id="pdf-content">
            <View style={styles.tableRow}>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Nome</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Código</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Entrada</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Saída</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Sub Total</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Pule</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>Detalhar</Text></View>
            </View>
            {cambistas.map((cambista) => (
              <View style={styles.tableRow} key={cambista.c3_id}>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{cambista.c3_nome}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{cambista.c3_codigo}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{(cambista.rifaEntrada ? Number(cambista.rifaEntrada) : 0) + (cambista.jbEntrada ? Number(cambista.jbEntrada) : 0) + (cambista.bingoEntrada ? Number(cambista.bingoEntrada) : 0) + (cambista.dezenaEntrada ? Number(cambista.dezenaEntrada) : 0)}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{(cambista.rifasaida ? Number(cambista.rifasaida) : 0) + (cambista.jbsaida ? Number(cambista.jbsaida) : 0) + (cambista.bingosaida ? Number(cambista.bingosaida) : 0) + (cambista.dezenasaida ? Number(cambista.dezenasaida) : 0)}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{((cambista.rifaEntrada ? Number(cambista.rifaEntrada) : 0) + (cambista.jbEntrada ? Number(cambista.jbEntrada) : 0) + (cambista.bingoEntrada ? Number(cambista.bingoEntrada) : 0) + (cambista.dezenaEntrada ? Number(cambista.dezenaEntrada) : 0)) - ((cambista.rifasaida ? Number(cambista.rifasaida) : 0) + (cambista.jbsaida ? Number(cambista.jbsaida) : 0) + (cambista.bingosaida ? Number(cambista.bingosaida) : 0) + (cambista.dezenasaida ? Number(cambista.dezenasaida) : 0))}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}>{cambista.a1_pule}</Text></View>
                <View style={styles.tableCol}><Text style={styles.tableCell}><button className="btn btn-primary btn-large" onClick={() => generatePDF(cambistas)}>Detalhar</button></Text></View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfGeral;
export { generatePDF };
