import React from 'react';
import moment from 'moment'; // Importe o pacote moment

const convert = ({ gerente }) => {
    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MM/YYYY');
    };

    return (
        <div>
           
        </div>
    );
};

export default convert;

export const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };

export const validateComissao = (comissao) => {
    const numericValue = comissao.toString().replace(/[^0-9]/g, '');
    const intValue = parseInt(numericValue, 10);
    if (intValue < 0) {
        return 0;
    } else if (isNaN(intValue) || intValue === null) {
        return 0;
    } else if (intValue > 100) {
        return 100;
    } else {
        return intValue;
    }
};
