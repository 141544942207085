import React, { useState, useEffect } from 'react';
import SideBar from './../SideBar';
import { Modal, Button } from 'react-bootstrap';
import api from "../../services/api";
import '../styles/paginations.css'
import '../styles/filter.css'
import { formatDate } from './../../convert';
import RingLoader from 'react-spinners/RingLoader';
import { FaSearch } from "react-icons/fa";
// import PdfGeral, { generatePDF } from './PdfGeral';

function RelatorioBingo() {
    const [loading, setLoading] = useState(false);
    const [dateInicio, setDateInicio] = useState(null);
    const [dateFinal, setDateFinal] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedCambista, setSelectedCambista] = useState('');
    const [selectedGerente, setSelectedGerente] = useState('null');
    const [gerentes, setGerentes] = useState([]);
    const [cambistas, setCambistas] = useState([]);
    const [seach, setSeach] = useState(false);
    const [searchCambista, setSearchCambista] = useState('');

    const handleSearchChange = (event) => {
        setSearchCambista(event.target.value);
    };

    const filteredCambistas = cambistas ? cambistas.filter(cambista => {
        return cambista.nome.toLowerCase().includes(searchCambista.toLowerCase());
    }) : [];

    const handleClose = () => setSeach(false);
    const handleShow = () => setSeach(true);

    useEffect(() => {
        loadGerentes();
        loadCambistas();
    }, [currentPage, dateInicio, dateFinal, selectedCambista, selectedGerente]);

    const loadCambistas = () => {
        setLoading(true);
        const params = {
            dateInicio: dateInicio,
            dateFinal: dateFinal,
            page: currentPage,
            limit: itemsPerPage,
            selectedGerente: selectedGerente,
            jb: false,
            rifa: true,
            bingo: true,
            dezena: false,
        };
        api.get(`/relatorios`, { params })
        .then((response) => {
                console.log('ta chmando?')
                setCambistas(response.data.relatorios);
                setTotalPages(response.data.totalPages);

            })
            .catch((error) => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const loadGerentes = () => {
        api.get(`/listGerentes`)
            .then((response) => {
                setGerentes(response.data.gerentes);
            })
            .catch((error) => {
            });
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Bingo
                                        <span className="sub-header-quote">Relatórios de Bingo</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                    {/* <Button variant="primary" onClick={generatePDF}>Gerar PDF</Button> Botão para gerar PDF */}
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-dark new-quote-btn" onClick={handleShow}>
                                            <FaSearch />
                                        </button>
                                    </div>
                                    <div>
                                        <div className="content">
                                            <div className="box-header with-border">
                                                <div className="row">
                                                    <div className="box-body">
                                                        <div className="table-responsive">
                                                            <table className="table table-bordered table-hover">
                                                                <thead className="tabela-class">
                                                                    <tr>
                                                                        <th className="tabela-class">NOME</th>
                                                                        <th className="tabela-class">TIPO</th>

                                                                        <th className="tabela-class">CÓDIGO</th>
                                                                        <th className="tabela-class">ENTRADA</th>
                                                                        <th className="tabela-class">SAIDA</th>
                                                                        <th className="tabela-class">COMISSÃO</th>
                                                                        {/* <th className="tabela-class">DESPESAS</th> */}
                                                                        <th className="tabela-class">TOTAL</th>
                                                                        {/* <th className="tabela-class">DETALHAR</th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="tabela-class">
                                                                {cambistas.map((cambista, index) => (
        <tr key={index}>
                                                                            <td>{cambista.nome}</td>
                                                                            <td>{cambista.tipo}</td>
                                                                            <td>{cambista.codigo}</td>
                                                                            <td>{cambista.bingo_entradas ? cambista.bingo_entradas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                                            <td>{cambista.bingo_saidas ? cambista.bingo_saidas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                                            <td>{cambista.bingo_comissoes ? cambista.bingo_comissoes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                                            <td>{cambista.total_bingo ? cambista.total_bingo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                                            {/* <td><button className="btn btn-primary btn-large">
                                                                                <FaSearch />
                                                                            </button></td> */}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            < div style={{ height: '50vh', display: 'flex', display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                                                                <RingLoader
                                                                    loading={loading}
                                                                    size={150}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                    css={{ margin: 'auto' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="pagination">
                                                            <button onClick={prevPage} disabled={currentPage === 1}>Anterior</button>
                                                            <span>Página {currentPage} de {totalPages}</span>
                                                            <button onClick={nextPage} disabled={currentPage === totalPages}>Próxima</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={seach} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Filtrar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter-section">
                        <div className="filter-item">
                            <label htmlFor="dateInicio">Data Inicio:</label>
                            <input
                                type="date"
                                id="dateInicio"
                                value={dateInicio || ''}
                                onChange={(e) => setDateInicio(e.target.value)}
                            />
                        </div>

                        <div className="filter-item">
                            <label htmlFor="dateFinal">Data Final:</label>
                            <input
                                type="date"
                                id="dateFinal"
                                value={dateFinal || ''}
                                onChange={(e) => setDateFinal(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="filter-section">
                        <div className="filter-item">
                            <label htmlFor="selectedGerente">Gerente:</label>

                            <select
                                id="selectedGerente"
                                value={selectedGerente}
                                onChange={(e) => setSelectedGerente(e.target.value)}
                            >
                                <option value={null} disabled>
                                    Selecione um gerente
                                </option>
                                <option value="null">
                                    Todos
                                </option>

                                {gerentes.map(gerente => (
                                    <option key={gerente.u1_id} value={gerente.u1_id}>
                                        {gerente.u1_nome}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-item">
                            <label htmlFor="searchCambista">Codigo Cambista:</label>
                            <input
                                type="text"
                                id="searchCambista"
                                value={searchCambista}
                                onChange={handleSearchChange}
                            />
                        </div>


                        <div className="filter-item">
                            <label htmlFor="selectedCambista">Cambista:</label>
                            <select
                                id="selectedCambista"
                                value={selectedCambista}
                                onChange={(e) => setSelectedCambista(e.target.value)}
                            >
                                <option value={null} disabled>
                                    Selecione um Cambista
                                </option>
                                <option value="null">
                                    Todos
                                </option>
                                {filteredCambistas.map((cambista, index) => (
    <option key={index} value={cambista.c3_id}>
        {cambista.nome} - {cambista.nome}
    </option>
))}

                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                    // onClick={}
                    >
                        + FILTRAR
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default RelatorioBingo;
//321