import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './../../convert';
import './../styles/Global.css';
import SideBar from './../SideBar';
import Swal from 'sweetalert2';
import RingLoader from 'react-spinners/RingLoader';

const CadastroCambista = () => {
    const [loading, setLoading] = useState(false);
    const [leituristas, setLeituristas] = useState([]);
    const [gerentes, setGerentes] = useState([]);
    const [regioes, setRegioes] = useState([]);
    const [cambista, setCambista] = useState({ comissoes: [] });
    const [clientes, setClientes] = useState([]);
    const [divAtiva, setDivAtiva] = useState(1);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [upCliente, setUpCliente] = useState([]);
    const [id, setId] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const handleEdit = (cliente) => {
        console.log(cliente);
        if (cliente) {
            setUpCliente({
                codigo: cliente.c6_codigo,
                afiliado: cliente.c6_afiliado,
                cep: cliente.c6_cep,
                email: cliente.c6_email,
                // login: cliente.c6_login,
                nome: cliente.c6_nome,
                cpf: cliente.c6_cpf,
                endereco: cliente.c6_endereco,
                telefone1: cliente.c6_telefone,
                bonus: cliente.c6_bonus,
                caixa: cliente.c6_caixa,
                status: cliente.c6_status,
                uf: cliente.c6_uf,
                nascimento: cliente.c6_data_nascimento,
            });
            setId(cliente.c6_id);
            handleShowEdit();
        } else {
            // Tratar o caso em que cliente é null
            console.error("Cliente está null ou indefinido.");
        }
    };
    

    const editCambista = () => {
        api.put(`/updateCliente/${id}`, { ...upCliente })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Cambista atualizado com sucesso',
                    timer: 7000,
                    timerProgressBar: true,
                });
                setShowEdit(false);
                // setUpCambista(null);
                loadClientes();
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'warning',
                    title: 'Houve algum erro!',
                    timer: 7000,
                    timerProgressBar: true,
                });
                // console.log(error);
            });
    };

    const ativarDesativar = async (id, ativo) => {
        try {
            await api.put(`/ativarDesativarCliente/${id}`, { ativo });
            loadClientes()
        } catch (error) {
            // Lidar com erros
        }
    };

    const deletCliente = (cliente) => {
        const id = cliente.c6_id;
        Swal.fire({
            title: `Tem certeza que deseja deletar o Cliente: ${cliente.c6_nome}?`,
            text: "Esta ação não pode ser desfeita.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, deletar!"
        }).then((result) => {
            if (result.isConfirmed) {
                // Enviar requisição DELETE para deletar o cambista
                api.delete(`/deletCliente/${id}`)
                    .then(response => {
                        Swal.fire({
                            title: "Deletado!",
                            text: `${cambista.c3_nome} foi deletado com sucesso.`,
                            icon: "success"
                        });
                        loadClientes();
                    })
                    .catch(error => {
                        Swal.fire({
                            title: "Erro!",
                            text: error.response.data.error || "Ocorreu um erro ao deletar o cambista.",
                            icon: "error"
                        });
                        // console.error(error);
                    });
            }
        });
    };

    useEffect(() => {
        loadLeituristas();
        loadGerentes();
        loadClientes();
        loadRegioes();
    }, []);

    const loadClientes = () => {
        setLoading(true)
        api.get(`/listClientes`)
            .then((response) => {
                setClientes(response.data.cliente);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const loadGerentes = () => {
        api.get(`/listGerentes`)
            .then((response) => {
                setGerentes(response.data.gerentes);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadLeituristas = () => {
        api.get(`/listLeituristas`)
            .then((response) => {
                setLeituristas(response.data.leiturista);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadRegioes = () => {
        api.get(`/listRegioes`)
            .then((response) => {
                setRegioes(response.data.regioes);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    function sendCambista() {
        let liberado = [];
        if (cambista.liberadoJb) {
            liberado.push('jb');
        }
        if (cambista.liberadoRifa) {
            liberado.push('acao');
        }
        if (cambista.liberadoBingo) {
            liberado.push('bg');
        }
        if (cambista.liberadoBolao) {
            liberado.push('bl');
        }
        if (cambista.liberadoDeposito) {
            liberado.push('dp');
        }
        if (cambista.liberadoSaque) {
            liberado.push('sq');
        }
        api.post(`/cadCambista`, { ...cambista, liberado })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Região cadastrada com sucesso',
                    timer: 7000,
                    timerProgressBar: true,
                });
                setShow(false)
                setCambista('')
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'warning',
                    title: 'Houve algum erro!',
                    timer: 7000,
                    timerProgressBar: true,
                });
            });
    }

    const renderizarDiv = (numero) => {
        setDivAtiva(numero);
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Clientes
                                        <span className="sub-header-quote">Cadastro de Cambista</span>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        {/* <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShow}>
                                            + Novo Cambista
                                        </button> */}
                                    </div>
                                    <div className="content">
                                        <table className="table table-bordered table-hover">
                                            <thead className="tabela-class">
                                                <tr>
                                                    <th className="tabela-class">NOME</th>
                                                    <th className="tabela-class">CAIXA</th>
                                                    <th className="tabela-class">CODIGO</th>
                                                    <th className="tabela-class">DATA CRIADO</th>
                                                    <th className="tabela-class">SITUAÇÃO</th>
                                                    {/* <th className="tabela-class">EDITAR</th> */}
                                                    <th className="tabela-class">DETALHAR</th>
                                                    {/* <th className="tabela-class">DELETAR</th> */}
                                                </tr>
                                            </thead>

                                            <tbody className="tabela-class">
                                                <>
                                                    {clientes.map(cliente => (
                                                        <tr key={cliente.c6_id}>
                                                            <td>{cliente.c6_nome}</td>
                                                            <td>{cliente.c6_caixa}</td>
                                                            <td>{cliente.c6_codigo}</td>
                                                            <td>{formatDate(cliente.created_at)}</td>
                                                            {cliente.c6_status === 'desativado' ? <td><button className="btn btn-warning" onClick={() => ativarDesativar(cliente.c6_id, 'ativo')}><i className="fa fa-times"></i></button></td>
                                                                                        : <td><button className="btn btn-success" onClick={() => ativarDesativar(cliente.c6_id, 'desativado')}><i className="fa fa-check"></i></button></td>}
                                                                                    
                                                            <td><button className="btn btn-primary btn-large" >
                                                                
                                                                <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(cliente)} />
                                                            </button></td>
                                                            {/* <td><button className="btn btn-danger btn-large">
                                                                <FontAwesomeIcon icon={faTrash} onClick={() => deletCliente(cliente)} /></button></td> */}
                                                        </tr>
                                                    ))}
                                                </>
                                            </tbody>
                                        </table>
                                        < div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <RingLoader
                                                                        loading={loading}
                                                                        size={150}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                        css={{ margin: 'auto' }}
                                                                    />
                                    </div>
                                    </div>
                                    <Modal show={show} onHide={handleClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>CADASTRAR CAMBISTA</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="centralizar">
                                                <div id="app" className="button-container">

                                                    <button
                                                        className={`btn btn-primary new-quote-btn ${divAtiva === 1 ? 'active' : ''}`}
                                                        onClick={() => renderizarDiv(1)}
                                                    >
                                                        Associar
                                                    </button>
                                                    <button
                                                        className={`btn btn-primary new-quote-btn ${divAtiva === 2 ? 'active' : ''}`}
                                                        onClick={() => renderizarDiv(2)}
                                                    >
                                                        Comissões
                                                    </button>
                                                    <button
                                                        className={`btn btn-primary new-quote-btn ${divAtiva === 3 ? 'active' : ''}`}
                                                        onClick={() => renderizarDiv(3)}
                                                    >
                                                        Dados
                                                    </button>
                                                </div>
                                            </div>

                                            {divAtiva === 1 && (
                                                <div className="div1">
                                                    <div className="row mb-3">
                                                        <div className="col-md-4">
                                                            <label htmlFor="nomeLoteria">Gerente:</label>
                                                            <select
                                                                className="form-select form-select-lg mb-3"
                                                                aria-label="Large select example"
                                                                value={cambista.selectedGerente}
                                                                onChange={(e) => setCambista({ ...cambista, selectedGerente: e.target.value })}
                                                            > <option value={null} disabled selected>
                                                                    Selecione um gerente
                                                                </option>
                                                                {gerentes.map(gerente => (
                                                                    <option key={gerente.u1_id} value={gerente.u1_id}>
                                                                        {gerente.u1_nome}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label htmlFor="nomeLoteria">Leiturista:</label>
                                                            <select className="form-select form-select-lg mb-3" aria-label="Large select example" value={cambista.selectedLeiturista}
                                                                onChange={(e) => setCambista({ ...cambista, selectedLeiturista: e.target.value })}>
                                                                <option value={null} disabled selected>
                                                                    Selecione um leiturista
                                                                </option>
                                                                {leituristas.map(leiturista => (
                                                                    <option key={leiturista.u1_id} value={leiturista.u1_id}>
                                                                        {leiturista.u1_nome}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label htmlFor="nomeLoteria">Região:</label>
                                                            <select className="form-select form-select-lg mb-3" aria-label="Large select example" value={cambista.selectedRegiao} onChange={(e) => setCambista({ ...cambista, selectedRegiao: e.target.value })}>
                                                                <option value={null} disabled selected>
                                                                    Selecione uma região
                                                                </option>
                                                                {regioes.map(regiao => (
                                                                    <option key={regiao.r1_id} value={regiao.r1_id}>
                                                                        {regiao.r1_nome}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Codigo</label>
                                                                <input type="text" className="form-control" placeholder="Insira o código" value={cambista.codigo} onChange={(e) => setCambista({ ...cambista, codigo: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Nome Pule</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome da pule" value={cambista.nomePule} onChange={(e) => setCambista({ ...cambista, nomePule: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Login</label>
                                                                <input type="text" className="form-control" placeholder="Insira o login" value={cambista.login} onChange={(e) => setCambista({ ...cambista, login: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Senha</label>
                                                                <input type="text" className="form-control" placeholder="Insira a senha" value={cambista.senha} onChange={(e) => setCambista({ ...cambista, senha: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Tempo para Cancelar</label>
                                                                <input type="number" className="form-control" placeholder="Insira o tempo de cancelamento" value={cambista.cancelar} onChange={(e) => setCambista({ ...cambista, cancelar: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Situação</label><br />
                                                                <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" checked />
                                                                <label className="btn btn-outline-success" htmlFor="success-outlined">Ativo</label>
                                                                <input type="radio" className="btn-check" id="btn-check-outlined" name="options-outlined" autoComplete="off" />
                                                                <label className="btn btn-outline-primary" htmlFor="btn-check-outlined">Teste</label>
                                                                <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" />
                                                                <label className="btn btn-outline-danger" htmlFor="danger-outlined">Bloq.</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {divAtiva === 2 && (
                                                <div className="div2">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">J.B</label>
                                                                <input type="number" className="form-control" placeholder="Insira a comissão do J.B" value={cambista.comJb} onChange={(e) => setCambista({ ...cambista, comJb: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Rifa</label>
                                                                <input type="number" className="form-control" placeholder="Insira a comissão da Rifa" value={cambista.comRifa} onChange={(e) => setCambista({ ...cambista, comRifa: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Bingo</label>
                                                                <input type="number" className="form-control" placeholder="Insira a comissão do bingo" value={cambista.comBingo} onChange={(e) => setCambista({ ...cambista, comBingo: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comJb" checked={cambista.liberadoJb} onChange={(e) => setCambista({ ...cambista, liberadoJb: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comJb">J.B</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comRifa" checked={cambista.liberadoRifa} onChange={(e) => setCambista({ ...cambista, liberadoRifa: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comRifa">Rifa</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comBingo" checked={cambista.liberadoBingo} onChange={(e) => setCambista({ ...cambista, liberadoBingo: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comBingo">Bingo</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comBolao" checked={cambista.liberadoBolao} onChange={(e) => setCambista({ ...cambista, liberadoBolao: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comBolao">Bolão</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comSaque" checked={cambista.liberadoSaque} onChange={(e) => setCambista({ ...cambista, liberadoSaque: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comSaque">Saque</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comDeposito" checked={cambista.liberadoDeposito} onChange={(e) => setCambista({ ...cambista, liberadoDeposito: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comDeposito">Depósito</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {divAtiva === 3 && (
                                                <div className="row mb-3">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Nome</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={cambista.nome} onChange={(e) => setCambista({ ...cambista, nome: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">CPF</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={cambista.cpf} onChange={(e) => setCambista({ ...cambista, cpf: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Nome Estabelecimento</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={cambista.nomeEstabelecimento} onChange={(e) => setCambista({ ...cambista, nomeEstabelecimento: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Endereço</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={cambista.endereco} onChange={(e) => setCambista({ ...cambista, endereco: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Telefone 1</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={cambista.telefone1} onChange={(e) => setCambista({ ...cambista, telefone1: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Telefone 2</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={cambista.telefone2} onChange={(e) => setCambista({ ...cambista, telefone2: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">CEP</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={cambista.cep} onChange={(e) => setCambista({ ...cambista, cep: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={sendCambista}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={showEdit} onHide={handleCloseEdit} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>DETALHE CLIENTE</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            
        <div className="centralizar">
            <h5>Detalhes do Cliente</h5>
            <hr />
            <div>
                <strong>Código:</strong> {upCliente.codigo}<br />
                <strong>Nome:</strong> {upCliente.nome}<br />
                <strong>CPF:</strong> {upCliente.cpf}<br />
                <strong>E-mail:</strong> {upCliente.email}<br />
                <strong>Telefone:</strong> {upCliente.telefone1}<br />
                <strong>Endereço:</strong> {upCliente.endereco}<br />
                <strong>CEP:</strong> {upCliente.cep}<br />
                <strong>UF:</strong> {upCliente.uf}<br />
                <strong>Data de Nascimento:</strong> {formatDate(upCliente.nascimento)}<br />
                <strong>Status:</strong> {upCliente.status}<br />
                <strong>Bônus:</strong> {upCliente.bonus}<br />
                <strong>Caixa:</strong> {upCliente.caixa}<br />
                <strong>Afiliado:</strong> {upCliente.afiliado}<br />
                {/* <strong>Login:</strong> {upCliente.login}<br /> */}
            </div>
        </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {/* <Button variant="primary" onClick={editCambista}>
                                                + EDITAR
                                            </Button> */}
                                            <Button variant="secondary" onClick={handleCloseEdit}>Fechar</Button>
        {/* <Button variant="primary" onClick={editCambista}>Salvar Alterações</Button> */}
  
                                        </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CadastroCambista;