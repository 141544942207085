import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from "../../services/api";
import SideBar from './../SideBar';
import ScaleLoader from "react-spinners/ScaleLoader";
import Swal from 'sweetalert2'


function CadBingo() {
    const [bingos, setBingos] = useState([]);
    const [formData, setFormData] = useState({
        horarioInicial: '',
        premio1: '',
        premio2: '',
        premio3: '',
        acumulado: '',
        valor: '',
    });
    const [formDup, setFormDup] = useState({
        diaDup: '',
        diaIns: '',
    });
    const [loading, setLoading] = useState(false);
    const [newBingo, setNewBingo] = useState(false);
    const [dupBingo, setDupBingo] = useState(false);


    const handleShowCad = () => setNewBingo(true);
    const handleCloseCad = () => setNewBingo(false);

    const handleShowDup = () => setDupBingo(true);
    const handleCloseDup = () => setDupBingo(false);

    useEffect(() => {
        loadRodadas();
    }, []);

    const loadRodadas = () => {
        setLoading(true);
        api.get(`/listBingoRodadaWeb`)
            .then((response) => {
                setBingos(response.data.rodadas);
            })
            .catch(error => {
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    const submitRaffle = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await api.post(`/cadBingo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
                icon: 'success',
                title: 'Rodada cadastrada com sucesso',
                timer: 7000,
                timerProgressBar: true,
            });
            loadRodadas();
            setLoading(false);
        } catch (error) {
            let errorMessage = 'Houve algum erro!';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            Swal.fire({
                icon: 'warning',
                title: errorMessage,
                timer: 7000,
                timerProgressBar: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const submitDup = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await api.post(`/dupBingo`, formDup, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
                icon: 'success',
                title: 'Rodada cadastrada com sucesso',
                timer: 7000,
                timerProgressBar: true,
            });
            loadRodadas();
            setLoading(false);
        } catch (error) {
            let errorMessage = 'Houve algum erro!';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            Swal.fire({
                icon: 'warning',
                title: errorMessage,
                timer: 7000,
                timerProgressBar: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const ativarDesativar = async (rifaId, ativo) => {
        try {
            await api.put(`/ativarDesativarRifa/${rifaId}`, { ativo });
            loadRodadas()
        } catch (error) {
        }
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                {/* <ScaleLoader color={"#000"} loading={loading} /> */}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Bingo
                                        <span className="sub-header-quote">Cadastro de Rodada</span>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    
                                        <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShowDup}>
                                            + Duplicar Rodadas
                                        </button>

                                        <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShowCad}>
                                            + Nova Rodada
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <div className="box box-primary">
                                                <div className="content">
                                                    <div className="box-header with-border">
                                                        <div className="row">
                                                            <div className="box-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-hover">
                                                                        <thead className="tabela-class">
                                                                            <tr>
                                                                                <th className="tabela-class">Horario</th>
                                                                                <th className="tabela-class">VALOR</th>
                                                                                <th className="tabela-class">KUADRA</th>
                                                                                <th className="tabela-class">KINA</th>
                                                                                <th className="tabela-class">KENO</th>
                                                                                <th className="tabela-class">Status</th>
                                                                                <th className="tabela-class">EDITAR</th>
                                                                                {/* <th className="tabela-class">DETALHAR</th> */}
                                                                                <th className="tabela-class">DELETAR</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="tabela-class">
                                                                            {bingos.map((bingo) => (
                                                                                <tr key={bingo.id}>
                                                                                    {/* <td>{new Date(bingo.inicio).toLocaleTimeString('pt-BR', {hour12: false})}</td> */}
                                                                                    <td>{new Date(bingo.inicio).toLocaleTimeString('pt-BR', { hour12: false, hour: '2-digit', minute: '2-digit' })}</td>
                                                                                    <td>{bingo.premio1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                    <td>{bingo.premio2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                    <td>{bingo.premio3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                    <td>{bingo.acumulado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                                    {bingo.status === 'sim' ? <td><button className="btn btn-warning" ><i className="fa fa-spinner fa-spin fa-3x fa-fw"></i></button></td>
                                                                                        : <td><button className="btn btn-success"><i className="fa fa-check"></i></button></td>}
                                                                                    <td><button className="btn btn-primary" data-bs-target="#editRifa"><i className="fa fa-edit"></i></button></td>
                                                                                    <td><button className="btn btn-danger"><i className="fa fa-trash"></i></button></td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                    <ScaleLoader color={"#000"} loading={loading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Modal show={newBingo} onHide={handleCloseCad} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Nova Rodada</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="modal-body">
                                                <div className="centralizar">
                                                    <div className="container">
                                                        <form onSubmit={submitRaffle} className="form">

                                                            <div className="row">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="horarioInicial" >Horario inicial</label>
                                                                            <input type="datetime-local" className="form-control" id="horarioInicial" name="horarioInicial" value={formData.horarioInicial} onChange={(e) => setFormData({ ...formData, horarioInicial: e.target.value })} required />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="ticketPrice">Preço do Bilhete (em R$):</label>
                                                                            <input type="number" value={formData.valor} onChange={(e) => setFormData({ ...formData, valor: e.target.value })} step="0.01" id="valor" className="form-control" required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="premio1">Valor da Kuadra:</label>
                                                                            <input type="text" id="premio1" className="form-control" value={formData.premio1} onChange={(e) => setFormData({ ...formData, premio1: e.target.value })} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="premio2">Valor da Kina:</label>
                                                                            <input type="text" id="premio2" className="form-control" value={formData.premio2} onChange={(e) => setFormData({ ...formData, premio2: e.target.value })} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="premio3">Valor do Keno:</label>
                                                                            <input type="text" id="premio3" className="form-control" value={formData.premio3} onChange={(e) => setFormData({ ...formData, premio3: e.target.value })} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="acumulado">Valor do Acumulado:</label>
                                                                            <input type="text" id="acumulado" className="form-control" value={formData.acumulado} onChange={(e) => setFormData({ ...formData, acumulado: e.target.value })} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={submitRaffle}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <Modal show={dupBingo} onHide={handleCloseDup} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Nova Rodada</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="modal-body">
                                                <div className="centralizar">
                                                    <div className="container">
                                                        <form onSubmit={submitDup} className="form">

                                                            <div className="row">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="diaDup" >Dia a duplicar</label>
                                                                            <input type="date" className="form-control" id="diaDup" name="diaDup" value={formDup.diaDup} onChange={(e) => setFormDup({ ...formDup, diaDup: e.target.value })} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <label htmlFor="diaIns" >Dia a ser inserido</label>
                                                                            <input type="date" className="form-control" id="diaIns" name="diaIns" value={formDup.diaIns} onChange={(e) => setFormDup({ ...formDup, diaIns: e.target.value })} required />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={submitDup}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CadBingo;
// 261
