import React, { useEffect } from 'react';
import Sidebar from './SideBar';
import { useAuth } from '../contexts/AuthContext.js';
const Home = (props) => {
  const { requireAuth,setIsRequireAuth,isRequireAuth } = useAuth();
  // useEffect(() => {
  //     setIsRequireAuth(props.requireAuth);
  //     requireAuth();

  //     // requireAuth(
  //     //   () => {
  //     //     //função anonima
  //     //   }
  //     // );
      
  //   // console.log('1');
  //   const token = localStorage.getItem('token');
  //   if (!token) {
  //     // Se o token não existir, redirecione para a página de login ou realize outra ação apropriada
  //     window.location.href = '/login'; // Redireciona para a página de login
  //   }
  // }, []);

  return (
    <div>
      {/* <div style={{ width: '10%' }}> */}
      <Sidebar />
      {/* <div style={{}}> */}
      {/* <h1>Bem-vindo à nossa aplicação!</h1> */}
      <text style={{textAlign: 'center'}}> </text>
      {/* </div> */}
      <p>Esta é a página inicial.</p>
      <p>Substitua este conteúdo com o que desejar.</p>
    </div>
  );
}

export default Home;
