import React,  { useEffect,useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from "./views/Login/Login";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import Home from './views/Home';
import Sidebar from './views/SideBar';

//GERAL
import CadastroGerente from './views/User/CadastroGerente';
import CadastroCambista from './views/User/CadastroCambista';
import CadastroLeiturista from './views/User/CadastroLeiturista';
import Cliente from './views/User/Cliente';

import RelatorioGeral from './views/Relatorios/Geral';


import CaixaFinanceiro from './views/Financeiro/Caixa';
// JB
import Cotacoes from './views/Cotacao/CadastroCotacao';
import Loterias from './views/Loteria/Loterias';
import Pule from './views/Pule/Pule';
import Regioes from './views/Regiao/Regioes';
import Resultado from './views/Resultado/Resultados';
import Descarga from './views/Descarga/Descarga';
// Rifa
import CadastroRifa from './views/Rifa/CadastroRifa';
import ResultadoRifa from './views/Rifa/ResultadoRifa';
import Lancamentos from './views/Lancamentos/Lancamentos';
import PuleRifa from './views/Pule/PuleRifa';
// CN
import CnLoja from './views/Cn/Loja';
import CnEquipamento from './views/Cn/Equipamento';
import CnDiario from './views/Cn/Relatorios/Diario';
// BG
import CadastroBingo from './views/Bingo/CadastroBingo';
import ResultadoBingo from './views/Bingo/ResultadoBingo';
import RelatorioBingo from './views/Bingo/RelatorioBingo';
// WEB
import WebAnuncios from './views/Web/WebAnuncios';



// import PrivateRoute from './PrivateRoute';

const App = () => {
    const { setIsRequireAuth, isRequireAuth,authenticated } = useAuth();
    const [tokenPermition, setTokenPermition] = useState(false);
    const [token, setToken] = useState(false);
    
    useEffect(() => { 
        const token = localStorage.getItem('token');
        setToken(token);
        if (!token && !tokenPermition) {
            // window.location.href = '/login';
        } else {
            // setIsRequireAuth(true);
            setTokenPermition(true)
        }
    }, [token, tokenPermition, authenticated]);

    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    {tokenPermition && (
                        <>
                    {/* pv */}
                    {/* <Route path="/Sidebar" element={<Sidebar />} /> */}
                    <Route path="/" element={<Home requireAuth={true}/>} 
                    />
                    {/* <Route path="/Regioes/*" element={<PrivateRoute element={<Regioes />} />} /> */}
                    <Route path="/Cadastro/Cambista" element={<CadastroCambista />} />
                    <Route path="/Cadastro/Gerente" element={<CadastroGerente />} />
                    <Route path="/Cadastro/Leiturista" element={<CadastroLeiturista />} />
                    <Route path="/Web/Cliente" element={<Cliente />} />

                    <Route path="/Relatorio/Geral" element={<RelatorioGeral />} />
                    <Route path="/Lancamentos" element={<Lancamentos/>}/>
                    {/* FALTA */}
                    <Route path="/Financeiro/Caixa" element={<CaixaFinanceiro />} />
                    {/* JB */}
                    <Route path="/Cadastro/Cotacoes" element={<Cotacoes />} />
                    <Route path="/Loterias" element={<Loterias />} />
                    <Route path="/pule/jb" element={<Pule />} />
                    <Route path="/Regioes" element={<Regioes />} />
                    <Route path="/Resultados" element={<Resultado />} />
                    <Route path="/Descarga" element={<Descarga />} />
                    {/* RIfa */}
                    <Route path="/Rifa/Cadastro/Rifa" element={<CadastroRifa />} />
                    <Route path="/Rifa/Resultado" element={<ResultadoRifa />} />
                    <Route path="/Pule/Rifa" element={<PuleRifa />} />
                    {/* Cn */}
                    <Route path="/Cn/Loja" element={<CnLoja />} />
                    <Route path="/Cn/Equipamento" element={<CnEquipamento />} />
                    <Route path="/Cn/Relatorios/Diario" element={<CnDiario />} />
                    {/* BG */}
                    <Route path="/Bingo/Cadastro/Bingo" element={<CadastroBingo />} />
                    {/* <Route path="/Bingo/Resultado" element={<ResultadoBingo/>} /> */}
                    <Route path="/Bingo/Relatorio" element={<RelatorioBingo/>} />
                    {/* WEB */}
                    <Route path="/Web/anuncios" element={<WebAnuncios/>} />




                    
                    </>
                    )}
                    <Route path="*" element={<Login />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;