import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from "../../services/api";
import SideBar from './../SideBar';
// import { VMoney } from 'v-money';

function ResultadoJb() {
    const [money] = useState({
        decimal: '',
        thousands: '',
        precision: 0,
        masked: true,
    });
    const [loterias, setLoterias] = useState([]);
    const [insResultados, setInsResultados] = useState({});
    const [nome, setNome] = useState('');
    const [sigla, setSigla] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [encerramento, setEncerramento] = useState('');
    const [numero1, setNumero1] = useState('');
    const [numero2, setNumero2] = useState('');
    const [numero3, setNumero3] = useState('');
    const [numero4, setNumero4] = useState('');
    const [numero5, setNumero5] = useState('');
    const [numero6, setNumero6] = useState('');
    const [numero7, setNumero7] = useState('');
    const [numero8, setNumero8] = useState('');
    const [numero9, setNumero9] = useState('');
    const [numero10, setNumero10] = useState('');
    const [inputDesabilitado, setInputDesabilitado] = useState(true);
    const [mostrarDiv1, setMostrarDiv1] = useState(false);
    const [mostrarDiv2, setMostrarDiv2] = useState(false);
    const [inserirResult, setInserirResult] = useState([]);
    const [modalResultadoInserir, setModalResultadoInserir] = useState(false);



    const [dataSelecionada, setDataSelecionada] = useState('');
    const handleChangeNumero1 = (event) => {
        setNumero1(event.target.value);
    };
    const handleChangeNumero2 = (event) => {
        setNumero2(event.target.value);
    };
    const handleChangeNumero3 = (event) => {
        setNumero3(event.target.value);
    };
    const handleChangeNumero4 = (event) => {
        setNumero4(event.target.value);
    };
    const handleChangeNumero5 = (event) => {
        setNumero5(event.target.value);
    };
    

    const openModalResultadoInserir = (loteria) => {
        setInserirResult(loteria);
        console.log(loteria);
        setModalResultadoInserir(true);
    };

    const closeModalResultadoInserir = () => {
        setModalResultadoInserir(false);
    };

    const loteriaContainer = useRef(null);

    useEffect(() => {
        loadLoterias();
    }, [dataSelecionada]);

    const loadLoterias = () => {
        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
        const dia = dataAtual.getDate().toString().padStart(2, '0');
        const data = `${ano}-${mes}-${dia}`;

        api.get(`/listResultados?data=${data}`)
            .then((response) => {
                setLoterias(response.data.loterias);
            })
            .catch(error => {
                console.log('Deu errado');
                console.log(error);
            });
    };

    const inserirResultado = (loteria) => {
        api.get(`/listLoteriaId/${loteria.l1_id}`)
            .then((response) => {
                setModalResultadoInserir(true)
                const data = response.data.loterias[0];
                setInsResultados(data);
                setNome(data.l1_nome);
                setSigla(data.l1_sigla);
                setQuantidade(data.l1_quantidade);
                setEncerramento(data.l1_enc_horario);
                // setL1Id(data.l1_id);
            })
            .catch(error => {
            });
    };

    const upLoteria = (l1_id, newValue) => {
        api.put(`/upLoteria/${l1_id}/${newValue}`)
            .then(() => {
                console.log('Loteria atualizada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar a loteria:', error);
            });
    };

    const sendResultado = (numero1, numero2, numero3, numero4, numero5, numero6, numero7, numero8, numero9, numero10) => {
        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
        const dia = dataAtual.getDate().toString().padStart(2, '0');
        const data = `${ano}-${mes}-${dia}`;

        const result = {
            id_loteria: inserirResult.l1_id,
            nome: inserirResult.l1_nome,
            primeiro: numero1,
            segundo: numero2,
            terceiro: numero3,
            quarto: numero4,
            quinto: numero5,
            sexto: numero6,
            setimo: numero7,
            oitavo: numero8 ?? 0,
            nono: numero9 ?? 0,
            decimo: numero10 ?? 0,
            data: data,
        };

        api.post(`/sendResult`, result)
            .then(() => {
                console.log('Resultado enviado com sucesso:', result);
            })
            .catch(error => {
                console.error('Erro ao enviar o resultado:', error);
            })
            .finally(() => {
            });
    };

    const calcularColocacao = () => {

        if (inserirResult.l1_quantidade === 7) {
            const num1 = parseInt(numero1, 10) || 0;
            const num2 = parseInt(numero2, 10) || 0;
            const num3 = parseInt(numero3, 10) || 0;
            const num4 = parseInt(numero4, 10) || 0;
            const num5 = parseInt(numero5, 10) || 0;
            const soma1 = (num1 + num2 + num3 + num4 + num5) % 10000;

            const cal = (num1 * num2).toString();
            const n1 = cal[cal.length - 6];
            const n2 = cal[cal.length - 5];
            const n3 = cal[cal.length - 4];
            const soma2 = `${n1}${n2}${n3}`;

            setNumero6(soma1);
            setNumero7(soma2);
            setNumero8(0);
            setNumero9(0);
            setNumero10(0);

            setMostrarDiv1(true);
            setMostrarDiv2(false);
            sendResultado(numero1, numero2, numero3, numero4, numero5, numero6, numero7, numero8, numero9, numero10);
        } else if (quantidade === 10) {
            const num1 = parseInt(numero1, 10) || 0;
            const num2 = parseInt(numero2, 10) || 0;
            const num3 = parseInt(numero3, 10) || 0;
            const num4 = parseInt(numero4, 10) || 0;
            const num5 = parseInt(numero5, 10) || 0;
            const soma10 = (num1 + num2 + num3 + num4 + num5) % 10000;

            const sex1 = numero1[numero1.length - 4];
            const sex2 = numero2[numero2.length - 4];
            const sex3 = numero3[numero3.length - 4];
            const sex4 = numero4[numero4.length - 4];
            const soma6 = `${sex1}${sex2}${sex3}${sex4}`;

            const set1 = numero1[numero1.length - 3];
            const set2 = numero2[numero2.length - 3];
            const set3 = numero3[numero3.length - 3];
            const set4 = numero4[numero4.length - 3];
            const soma7 = `${set1}${set2}${set3}${set4}`;

            const oit1 = numero1[numero1.length - 2];
            const oit2 = numero2[numero2.length - 2];
            const oit3 = numero3[numero3.length - 2];
            const oit4 = numero4[numero4.length - 2];
            const soma8 = `${oit1}${oit2}${oit3}${oit4}`;

            const non1 = numero1[numero1.length - 1];
            const non2 = numero2[numero2.length - 1];
            const non3 = numero3[numero3.length - 1];
            const non4 = numero4[numero4.length - 1];
            const soma9 = `${non1}${non2}${non3}${non4}`;

            setNumero6(soma6);
            setNumero7(soma7);
            setNumero8(soma8);
            setNumero9(soma9);
            setNumero10(soma10);
            setMostrarDiv1(true);
            setMostrarDiv2(true);
            sendResultado(numero1, numero2, numero3, numero4, numero5, numero6, numero7, numero8, numero9, numero10);
        } else {
            setMostrarDiv1(false);
            setMostrarDiv2(false);
        }
    };

    const scrollEsquerda = () => {
        const container = loteriaContainer.current;
        container.scrollLeft -= 600; // ou qualquer valor que você preferir
    };

    const scrollDireita = () => {
        const container = loteriaContainer.current;
        container.scrollLeft += 600; // ou qualquer valor que você preferir
    };

    return (
        <div>
            <div className="container1">
                <SideBar />
                <div className="content">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <div className="main-body">
                                <div className="page-wrapper">
                                    <div className="container mt-4">
                                        <div className="header-loteria">
                                            <i className="fas fa-ticket-alt"></i> Resultado <span className="sub-header-quote">Inserir resultados</span>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <input type="date" className="btn btn-dark new-quote-btn" value={dataSelecionada} onChange={(e) => setDataSelecionada(e.target.value)} />
                                        </div>
                                        <main>
                                            <div className="d-flex justify-content-between mb-3">
                                                <button type="button" className="btn" id="btnScrollEsquerda" onClick={scrollEsquerda}>
                                                    <ion-icon style={{ fontSize: '30px' }} name="arrow-back-circle-outline"></ion-icon>
                                                </button>
                                                <div className="loteria-container" ref={loteriaContainer}>
                                                    {loterias.map(loteria => (
                                                        <div id="card" className={`loteria-card ${loteria.presente === 'não' ? 'borda-vermelha' : ''} ${loteria.presente === 'sim' ? 'borda-verde' : ''}`} key={loteria.l1_id}>
                                                            <div className="largura-card">
                                                                <h3>{loteria.l1_nome}</h3>
                                                                <h4>Sigla: {loteria.l1_sigla}</h4>
                                                                <div className="dias-semana">
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck1"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <h5>Encerramento: <b>{loteria.l1_enc_horario}</b></h5>
                                                                <div className="row">
                                                                <div className="d-flex justify-content-between">
                                                                    <button type="button" className="btn btn-outline-primary" onClick={() => openModalResultadoInserir(loteria)}>
                                                                        Inserir
                                                                    </button>
                                                                    <button type="button" className="btn btn-outline-primary" onClick={() => inserirResultado(loteria.l1_id)}>
                                                                        Editar
                                                                    </button>
                                                                    <button className="btn btn-outline-success" style={{ display: loteria.l1_ativo === 0 ? 'block' : 'none' }} onClick={() => upLoteria(loteria.l1_id, 0)}>
                                                                        <i className="fa fa-unlock"></i>
                                                                    </button>
                                                                    <button className="btn btn-outline-warning" style={{ display: loteria.l1_ativo === 1 ? 'block' : 'none' }} onClick={() => upLoteria(loteria.l1_id, 1)}>
                                                                        <i className="fa fa-lock"></i>
                                                                    </button>
                                                                </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <button type="button" className="btn btn-lg" id="btnScrollDireita" onClick={scrollDireita}>
                                                    <ion-icon style={{ fontSize: '30px' }} name="arrow-forward-circle-outline"></ion-icon>
                                                </button>
                                            </div>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Inserir Resultado */}
            <Modal show={modalResultadoInserir} onHide={closeModalResultadoInserir} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Inserir Resultado {inserirResult.l1_nome}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="modal-content">

                        <div className="modal-body">
                            <div className="container">
                                <div className="row mb-3">
                                    <div className="col-md-6 modal-label">
                                        <label htmlFor="nomeLoteria">Nome da Loteria:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="siglaLoteria">{inserirResult.l1_nome}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6 modal-label">
                                        <label htmlFor="siglaLoteria">Sigla:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="siglaLoteria">{inserirResult.l1_sigla}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6 modal-label">
                                        <label htmlFor="encerramento">Encerramento:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="siglaLoteria">{inserirResult.l1_enc_horario}</label>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="dias-semana-editar">
                                <div className="dia">
                                    <div className="row mb-3">
                                        <div className="dias-semana-editar">
                                            <div className="dia">
                                                <div className="row mb-3">
                                                    <div className="form-check">
                                                        <label className="form-check-label">1º:</label>
                                                        <input id="field" type="text" maxLength="4"
                                                            pattern="([0-9]{4})" value={numero1}
                                                            onChange={handleChangeNumero1}
                                                            money={money}/>
                                                        {/* <label className="form-check-label">{mensagem}</label> */}
                                                    </div>

                                                    <div className="form-check">
                                                        <label className="form-check-label">2º:</label>
                                                        <input id="field" type="text" maxLength="4"
                                                            pattern="([0-9]{4})" value={numero2}
                                                            onChange={handleChangeNumero2}
                                                            money={money} />
                                                        {/* <label className="form-check-label">{mensagem}</label> */}
                                                    </div>
                                                    <div className="form-check">
                                                        <label className="form-check-label">3º:</label>
                                                        <input id="field" type="text" maxLength="4"
                                                            pattern="([0-9]{4})" value={numero3}
                                                            onChange={handleChangeNumero3}
                                                            money={money} />
                                                        {/* <label className="form-check-label">{mensagem}</label> */}
                                                    </div>
                                                    <div className="form-check">
                                                        <label className="form-check-label">4º:</label>
                                                        <input id="field" type="text" maxLength="4"
                                                            pattern="([0-9]{4})" value={numero4}
                                                            onChange={handleChangeNumero4}
                                                            money={money} />
                                                        {/* <label className="form-check-label">{mensagem}</label> */}
                                                    </div>
                                                    <div className="form-check">
                                                        <label className="form-check-label">5º:</label>
                                                        <input id="field" type="text" maxLength="4"
                                                            pattern="([0-9]{4})" value={numero5}
                                                            onChange={handleChangeNumero5}
                                                            money={money} />
                                                        {/* <label className="form-check-label">{mensagem}</label> */}
                                                    </div>
                                                    <div className="row mb-3"
                                                        style={{ display: mostrarDiv1 ? 'block' : 'none' }}>
                                                        <div className="form-check">
                                                            <label className="form-check-label">6º:</label>
                                                            <input id="field" type="text" maxLength="4"
                                                                pattern="([0-9]{4})" value={numero6}
                                                                // onChange={handleChangeNumero6}
                                                                disabled={inputDesabilitado}
                                                                money={money} />
                                                            {/* <label className="form-check-label">{mensagem}</label> */}
                                                        </div>
                                                        <div className="form-check">
                                                            <label className="form-check-label">7º:</label>
                                                            <input id="field" type="text" maxLength="4"
                                                                pattern="([0-9]{4})" value={numero7}
                                                                // onChange={handleChangeNumero7}
                                                                disabled={inputDesabilitado}
                                                                money={money} />
                                                            {/* <label className="form-check-label">{mensagem}</label> */}
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3"
                                                        style={{ display: mostrarDiv2 ? 'block' : 'none' }}>
                                                        <div className="form-check">
                                                            <label className="form-check-label">8º:</label>
                                                            <input id="field" type="text" maxLength="4"
                                                                pattern="([0-9]{4})" value={numero8}
                                                                // onChange={handleChangeNumero8}
                                                                disabled={inputDesabilitado}
                                                                money={money} />
                                                            {/* <label className="form-check-label">{mensagem}</label> */}
                                                        </div>
                                                        <div className="form-check">
                                                            <label className="form-check-label">9º:</label>
                                                            <input id="field" type="text" maxLength="4"
                                                                pattern="([0-9]{4})" value={numero9}
                                                                // onChange={handleChangeNumero9}
                                                                disabled={inputDesabilitado}
                                                                money={money} />
                                                            {/* <label className="form-check-label">{mensagem}</label> */}
                                                        </div>
                                                        <div className="form-check">
                                                            <label className="form-check-label">10º:</label>
                                                            <input id="field" type="text" maxLength="4"
                                                                pattern="([0-9]{4})" value={numero10}
                                                                // onChange={handleChangeNumero10}
                                                                disabled={inputDesabilitado}
                                                                money={money} />
                                                            {/* <label className="form-check-label">{mensagem}</label> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <Button variant="primary" onClick={calcularColocacao}>
                        + LANÇAR
                    </Button>
                </Modal.Footer>
            </Modal >

        </div>
    );
}

export default ResultadoJb;
