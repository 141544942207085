import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import api from "../../services/api";
import SideBar from './../SideBar';
import { useAuth } from '../../contexts/AuthContext.js';
import RingLoader from 'react-spinners/RingLoader';


const CadastroCotacao = (props) => {
    const [loading, setLoading] = useState(false);
    const { requireAuth,setIsRequireAuth,isRequireAuth } = useAuth();
    const [money] = useState({
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
    });
    const [formulario, setFormulario] = useState(true);
    const [modalidade, setModalidade] = useState({});
    const [modalidades, setModalidades] = useState([]);
    const [cotacao, setCotacao] = useState({
        nome: '',
        descricao: '',
        modalidades: {
            valor: '0',
        },
    });
    const [cotacoes, setCotacoes] = useState([]);
    const [cotacoesId, setCotacoesId] = useState({});
    const [modalNewCot, setModalNewCot] = useState(false);
    const [modalEditCot, setModalEditCot] = useState(false);
    const [upCotacao, setUpCotacao] = useState(
        {
            id: '',
            nome: '',
            descricao: '',
            cotacoes: [],
        }
    );


    const handleCloseCad = () => setModalNewCot(false);
    const handleCloseUp = () => setModalEditCot(false);
    const handleShowCad = () => setModalNewCot(true);
    const handleShowUp = () => setModalEditCot(true);

    const handleNomeChange = (event) => {
        setCotacao(prevCotacao => ({ ...prevCotacao, nome: event.target.value }));
    };
    const handleDescricaoChange = (event) => {
        setCotacao(prevCotacao => ({ ...prevCotacao, descricao: event.target.value }));
    };

    const closeButtonRef = useRef(null);

    const updateCotacao = (cotacoe) => {
        // console.log(JSON.stringify(cotacoe, null, 2));
        const cotacoesArray = JSON.parse(cotacoe.c2_cotacao);
        setUpCotacao(
            { 
                id: cotacoe.c2_id,
                nome: cotacoe.c2_nome,
                descricao: cotacoe.c2_descricao,
                cotacoes: cotacoesArray.map(cotacao => ({
                    id: cotacao.id,
                    nome: cotacao.nome,
                    tipo: cotacao.tipo,
                    ativo: cotacao.ativo,
                    nivel: cotacao.nivel,
                    nomeD: cotacao.nomeD,
                    sigla: cotacao.sigla,
                    valor: cotacao.valor,
                    numMax: cotacao.numMax,
                    tipoPalpite: cotacao.tipoPalpite
                })),
            });
        handleShowUp()
    };

    const handleUpdateCotacaoValue = (index, novoValor) => {
        const novasCotacoes = [...upCotacao.cotacoes];
        novasCotacoes[index].valor = novoValor;
        setUpCotacao({ ...upCotacao, cotacoes: novasCotacoes });
    };

    
    const editcotacao = () => {
        // console.table(upCotacao)
        api.put(`/updateCotacao/${upCotacao.id}`, { ...upCotacao })
        .then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Loteria atualizada com sucesso',
                timer: 7000,
                timerProgressBar: true,
            });
            setModalEditCot(false);
            loadCotacoes();
        })
        .catch((error) => {
            Swal.fire({
                icon: 'warning',
                title: 'Houve algum erro!',
                timer: 7000,
                timerProgressBar: true,
            });
            // console.log(error);
        });
    }

    useEffect(() => {
        loadCotacoes();
        dadosNovaModalidade();
        initModalidades();
    }, []);


    const initModalidades = () => {
        setModalidades(prevModalidades =>
            prevModalidades.map(modalidade => ({ ...modalidade, valor: 0 }))
        );
    };

    const loadCotacoes = () => {
        setLoading(true)
        api.get(`/listCotacoes`)
            .then((response) => {
                setCotacoes(response.data.cotacoes);
            })
            .catch(error => {
            })
            .finally(() => {
        setLoading(false)
                setFormulario(true);
            });
    };

    const handleModalidadeChange = (modalidadeId, novoValor) => {
        const modalidadesAtualizadas = modalidades.map(modalidade => {
            if (modalidade.id === modalidadeId) {
                return {
                    ...modalidade,
                    valor: novoValor,
                };
            }
            return modalidade;
        });
        setModalidades(modalidadesAtualizadas);
    };
    

    const dadosNovaModalidade = () => {
        api.get(`/listModalidades`)
            .then((response) => {
                   // Atualiza modalidades no estado com os valores corretos
            const modalidadesAtualizadas = response.data.modalidades.map(modalidade => ({
                ...modalidade,
                valor: 0,  // ou outra inicialização, se necessário
            }));
                setModalidades(modalidadesAtualizadas);
                // console.log('Loteria Recebida:', response.data.modalidades);
            })
            .catch(error => {
                // console.log('Erro ao obter a loteria:', error);
            });
    };

    // const sendCotacao = () => {
    //     setFormulario(false);
    //     modalidades.forEach(modalidade => {
    //         if (modalidade.valor === undefined || modalidade.valor === null) {
    //             modalidade.valor = 0;
    //         }
    //     });
    //     const modalidadesPreenchidas = modalidades.filter(modalidade =>
    //         modalidade.valor !== undefined && modalidade.valor !== null
    //     );
    //     setCotacao(prevCotacao => ({
    //         ...prevCotacao,
    //         modalidades: modalidadesPreenchidas
    //     }));
    //     api.post(`/cadCotacao`, cotacao)
    //     console.log(cotacao)
    //         .then(() => {
    //             const Toast = Swal.mixin({
    //                 toast: true,
    //                 position: "top-end",
    //                 showConfirmButton: false,
    //                 timer: 7000,
    //                 timerProgressBar: true,
    //                 didOpen: (toast) => {
    //                     toast.onmouseenter = Swal.stopTimer;
    //                     toast.onmouseleave = Swal.resumeTimer;
    //                 }
    //             });
    //             Toast.fire({
    //                 icon: "success",
    //                 title: "Cotação cadastrada com sucesso"
    //             });
    //             loadCotacoes()
    //             setModalNewCot(false)
    //         })
    //         .catch(() => {
    //             const Toast = Swal.mixin({
    //                 toast: true,
    //                 position: "top-end",
    //                 showConfirmButton: false,
    //                 timer: 7000,
    //                 timerProgressBar: true,
    //                 didOpen: (toast) => {
    //                     toast.onmouseenter = Swal.stopTimer;
    //                     toast.onmouseleave = Swal.resumeTimer;
    //                 }
    //             });
    //             Toast.fire({
    //                 icon: "warning",
    //                 title: "houve algum error!!"
    //             });
    //         })
    //         .finally(() => {
    //             setFormulario(true);
    //             setCotacao({});
    //         });
    // };

    const sendCotacao = () => {
        setFormulario(false);
    
        // Filtra as modalidades válidas
        const modalidadesPreenchidas = modalidades.map(modalidade => ({
            id: modalidade.id,
            nome: modalidade.nome,
            tipo: modalidade.tipo,
            ativo: modalidade.ativo,
            nivel: modalidade.nivel,
            nomeD: modalidade.nomeD,
            sigla: modalidade.	sigla,
            valor: modalidade.valor || 0, 
            numMax: modalidade.numMax,
            tipoPalpite: modalidade.tipoPalpite
        }));
    
        const cotacaoEnviada = {
            nome: cotacao.nome,
            descricao: cotacao.descricao,
            modalidades: modalidadesPreenchidas,
        };
    
        api.post(`/cadCotacao`, cotacaoEnviada)
            .then(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 7000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "success",
                    title: "Cotação cadastrada com sucesso"
                });
                loadCotacoes();
                setModalNewCot(false);
            })
            .catch(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 7000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "warning",
                    title: "Houve algum erro ao cadastrar a cotação"
                });
            })
            .finally(() => {
                setFormulario(true);
                setCotacao({});
            });
    };
    

    const scrollEsquerda = () => {
        const container = closeButtonRef.current;
        container.scrollLeft -= 600; // ou qualquer valor que você preferir
    };

    const scrollDireita = () => {
        const container = closeButtonRef.current;
        container.scrollLeft += 600; // ou qualquer valor que você preferir
    };

    const deleteCotacao = (c2_id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Realmente deseja deletar?",
            text: "Cotação será deletada permanentemente!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim deletar!",
            cancelButtonText: "Não deletar!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                api.post(`/cancelarCotacao${c2_id}`)
                    .then(() => {
                        loadCotacoes();
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 7000,
                            timerProgressBar: true,
                            didOpen:
                                (toast) => {
                                    toast.onmouseenter = Swal.stopTimer;
                                    toast.onmouseleave = Swal.resumeTimer;
                                }
                        });
                        Toast.fire({
                            icon: "success",
                            title: "Cotação deletada com sucesso"
                        });
                    })
                    .catch(() => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 7000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.onmouseenter = Swal.stopTimer;
                                toast.onmouseleave = Swal.resumeTimer;
                            }
                        });
                        Toast.fire({
                            icon: "warning",
                            title: "Cotação associada a uma regiao!!"
                        });
                    })
                    .finally(() => {
                        setFormulario(true);
                        setCotacao({});
                    });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                    title: "Cotação não deletada",
                    icon: "error"
                });
            }
        });
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Cotações <span className="sub-header-quote">cadastro de Cotações</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-dark new-quote-btn" onClick={handleShowCad}>
                                            + Nova Cotação
                                        </button>
                                    </div>
                                    <main>
                                        <div className="d-flex justify-content-between mb-3">
                                            <button type="button" className="btn" id="btnScrollEsquerda" onClick={scrollEsquerda}>
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-back-circle-outline"></ion-icon>
                                            </button>

                                            <div className="loteria-container">
                                                {cotacoes.map((cotacoe) => (
                                                    <div id="card" className="loteria-card" key={cotacoe.c2_id}>
                                                        <div className="largura-card">
                                                            <h3>{cotacoe.c2_nome}</h3>
                                                            <h4>{cotacoe.c2_descricao}</h4>
                                                            <div className="dias-semana">
                                                                <div className="dia">
                                                                    <div className="form-check-loteria">
                                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="button-container"> */}
                                        <div className="d-flex justify-content-between mb-3">
                                                                <button type="button" className="btn btn-primary" onClick={() => updateCotacao(cotacoe)}>
                                                                <FontAwesomeIcon size='2x' icon={faEdit}/>
                                                                </button>
                                                                <button type="button" className="btn btn-danger" onClick={() => deleteCotacao(cotacoe.c2_id)}>
                                                                <FontAwesomeIcon size='2x' icon={faTrash}/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" className="btn btn-lg " id="btnScrollDireita" onClick={scrollDireita}>
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-forward-circle-outline"></ion-icon>
                                            </button>
                                        </div>
                                        < div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <RingLoader
                                                                        loading={loading}
                                                                        size={150}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                        css={{ margin: 'auto' }}
                                                                    />
                                    </div>
                                        <Modal show={modalNewCot} onHide={handleCloseCad} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>Nova Cotação</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <div className="container">
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 modal-label">
                                                                <label htmlFor="nomeLoteria">Nome da Cotação:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control" id="nomeLoteria" value={cotacao.nome} onChange={handleNomeChange} />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 modal-label">
                                                                <label htmlFor="siglaLoteria">Descrição:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control" id="siglaLoteria" value={cotacao.descricao} onChange={handleDescricaoChange} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="dias-semana-editar">
                                                        <div className="dia">
                                                            <div className="row mb-3">
                                                                <div className="form-check">
                                                                    {modalidades.map(modalidade => (
                                                                        <div key={modalidade.id}>
                                                                            <label>{modalidade.nome}</label>
                                                                            <input type="text" className="form-control" placeholder="0,00" id={modalidade.nome} value={modalidade.valor}   onChange={(event) => handleModalidadeChange(modalidade.id, event.target.value)}  required />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={sendCotacao}>
                                                    + CADASTRAR
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal show={modalEditCot} onHide={handleCloseUp} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>EDITAR COTAÇÃO</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>

                                            <div className="modal-body">
                                                    <div className="container">
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 modal-label">
                                                                <label htmlFor="nomeLoteria">Nome da Cotação:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input type="text" className="form-control" id="modalNomeCotacao" name="nome" value={upCotacao.nome} onChange={(e) => setUpCotacao({ ...upCotacao, nome: e.target.value })} required />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 modal-label">
                                                                <label htmlFor="siglaLoteria">Descrição:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                            <input type="text" className="form-control" id="modalNomeDescricao" name="descricao" value={upCotacao.descricao} onChange={(e) => setUpCotacao({ ...upCotacao, descricao: e.target.value })} required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="dias-semana-editar">
                                                        <div className="dia">
                                                            <div className="row mb-3">
                                                                <div className="form-check">
                                                                {upCotacao.cotacoes.map((cotacao, index) => (
                                <div key={index}>
                                    <label>{cotacao.nome}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={cotacao.valor}
                                        onChange={(e) => handleUpdateCotacaoValue(index, e.target.value)}
                                        required
                                    />
                                </div>
                            ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={editcotacao}>
                                                    + SALVAR
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CadastroCotacao;
