import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './../../convert';
import './../styles/Global.css';
import SideBar from './../SideBar';
import RingLoader from 'react-spinners/RingLoader';

const CadastroLeiturista = () => {
    const [loading, setLoading] = useState(false);

    const [leituristas, setLeituristas] = useState([]);
    const [leiturista, setLeiturista] = useState({
        comissao_jb: '',
        comissao_rifa: '',
        comissao_bingo: '',
        tipo_comissao_jb: true,
        tipo_comissao_rifa: true,
        tipo_comissao_bingo: true,
        login: '',
        senha: '',
        codigo: '',
        salario: '',
        salarioData: '',
        nome: '',
        endereco: '',
        cep: '',
        telefone1: '',
        telefone2: '',
        obs: ''
    });
    const [show, setShow] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingLeiturista, setEditingLeiturista] = useState({});

    useEffect(() => {
        loadLeituristas();
    }, []);
    // modal cadastro
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // modal atualizar
    const handleUpdateClose = () => setShowEditModal(false);
    // const handleUpdateShow = () => setShowEditModal(true);

    const handleUpdateShow = (leiturista) => {
        leiturista.u1_senha = null;
        setEditingLeiturista(leiturista);
        setShowEditModal(true);
    };

    const loadLeituristas = () => {
        setLoading(true)
        api.get(`/listLeituristas`)
            .then((response) => {
                console.log(response)
                setLeituristas(response.data.leiturista);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    function updateLeiturista() {
        api.put(`/updateLeiturista/${editingLeiturista.u1_id}`, editingLeiturista)
            .then(() => {
                setShowEditModal(false);
                loadLeituristas();
            })
            .catch((error) => {
                console.log('Erro ao atualizar leiturista:', error);
            });
    }

    function sendLeiturista() {
        api.post(`/cadLeiturista`, leiturista)
            .then(() => {
                loadLeituristas();
        setShow(false)
        setLeiturista([])
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Leituristas
                                        <span className="sub-header-quote">Cadastro de Leiturista</span>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShow}>
                                            + Novo Leiturista
                                        </button>
                                    </div>
                                                <div className="content">
                                                    <div className="box-header with-border">
                                                        <div className="row">
                                                            <div className="box-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-hover">
                                                                        <thead className="tabela-class">
                                                                            <tr>
                                                                            <th className="tabela-class">NOME</th>
                                                                            <th className="tabela-class">CAIXA</th>
                                                                            <th className="tabela-class">Q. CAMBISTAS</th>
                                                                            <th className="tabela-class">DATA CRIADO</th>
                                                                            <th className="tabela-class">EDITAR</th>
                                                                            {/* <th className="tabela-class">DETALHAR</th> */}
                                                                            <th className="tabela-class">DELETAR</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="tabela-class">
                                                                            <>
                                                                                {leituristas.map(leiturista => (
                                                                                    <tr key={leiturista.u1_id}>
                                                                                        <td>{leiturista.u1_nome}</td>
                                                                                        <td>{leiturista.u1_caixa}</td>
                                                                                        <td>{leiturista.total_cambistas}</td>
                                                                                        <td>{formatDate(leiturista.created_at)}</td>
                                                                                        <td> <button className="btn btn-primary" onClick={() => handleUpdateShow(leiturista)}>
                                                                                        <i className="fa fa- fa-fw"> <FontAwesomeIcon icon={faEdit} /></i>
                                                                                        </button></td>
                                                                                        {/* <td><button className="btn btn-primary">
                                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                                        </button></td> */}
                                                                                        <td><button className="btn btn-danger">
                                                                                        <i className="fa fa- fa-fw"> <FontAwesomeIcon icon={faTrash} /></i></button></td>
                                                                                    </tr>
                                                                                ))}
                                                                            </>
                                                                        </tbody>
                                                                    </table>
                                                                    < div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <RingLoader
                                                                        loading={loading}
                                                                        size={150}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                        css={{ margin: 'auto' }}
                                                                    />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                    <Modal show={show} onHide={handleClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>CADASTRAR LEITURISTA</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="centralizar">
                                                <div id="app" className="button-container">
                                                    <div className="div1">
                                                        <div className="row mb-3"></div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Login</label>
                                                                    <input type="text" className="form-control" required placeholder="Insira o login" value={leiturista.login} onChange={(e) => setLeiturista({ ...leiturista, login: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Senha</label>
                                                                    <input type="text" className="form-control" required placeholder="Insira o senha" value={leiturista.senha} onChange={(e) => setLeiturista({ ...leiturista, senha: e.target.value })} />
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>
                                                    {/* <!-- aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii --> */}

                                                </div>

                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label for="exampleInputEmail1">Salario
                                                                Fixo</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Insira o nome"
                                                                value={leiturista.salario} onChange={(e) => setLeiturista({ ...leiturista, salario: e.target.value })}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Data Salario</label>
                                                            <select className="form-control" value={leiturista.salarioData} onChange={(e) => setLeiturista({ ...leiturista, salarioData: e.target.value })}>
    {[...Array(29)].map((_, index) => (
        <option key={index + 1} value={index + 1}>{index + 1}</option>
    ))}
</select>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <h3> Dados pessoais</h3>
                                            <div>
                                                <div className="row mb-3">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Nome</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o nome"
                                                                    value={leiturista.nome} onChange={(e) => setLeiturista({ ...leiturista, nome: e.target.value })}/>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label
                                                                    for="exampleInputEmail1">Endereço</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o Endereço"
                                                                    value={leiturista.endereco} onChange={(e) => setLeiturista({ ...leiturista, endereco: e.target.value })}/>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">CEP</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o CEP"
                                                                    value={leiturista.cep} onChange={(e) => setLeiturista({ ...leiturista, cep: e.target.value })}/>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Telefone
                                                                    1</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o Telefone 1"
                                                                    value={leiturista.telefone1} onChange={(e) => setLeiturista({ ...leiturista, telefone1: e.target.value })}/>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Telefone
                                                                    2</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o Telefone 2"
                                                                    value={leiturista.telefone2} onChange={(e) => setLeiturista({ ...leiturista, telefone2: e.target.value })}/>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label for="exampleInputEmail1">Obs:</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira uma observação"
                                                                    value={leiturista.obs} onChange={(e) => setLeiturista({ ...leiturista, obs: e.target.value })}/>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </Modal.Body >
                                        <Modal.Footer>
                                            {/* <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button> */}
                                            <Button variant="primary" onClick={sendLeiturista}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal >

                                    <Modal show={showEditModal} onHide={handleUpdateClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Atualizar Leiturista</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="centralizar">
                                                <div id="app" className="button-container">
                                                    <div className="div1">
                                                        <div className="row mb-3"></div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Login</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o login" value={editingLeiturista.u1_login} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_login: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Senha</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o senha" value={editingLeiturista.u1_senha} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_senha: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii --> */}

                                                    <div className="div2">
                                                        <h3> Comissões</h3>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Salário Fixo</label>
                                                            <input type="text" className="form-control" placeholder="Insira o salário" value={editingLeiturista.u1_salario} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_salario: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Data Salario</label>
                                                            <select className="form-control" value={editingLeiturista.u1_dia_salario} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_dia_salario: e.target.value })}>
                                                                {[...Array(29)].map((_, index) => (
                                                                    <option key={index + 1} value={index + 1}>{index + 1}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <h3> Dados pessoais</h3>
                                            <div>
                                                <div className="row mb-3">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Nome</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={editingLeiturista.u1_nome} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_nome: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Endereço</label>
                                                                <input type="text" className="form-control" placeholder="Insira o endereço" value={editingLeiturista.u1_endereco} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_endereco: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>CEP</label>
                                                                <input type="text" className="form-control" placeholder="Insira o CEP" value={editingLeiturista.u1_cep} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_cep: e.target.value })} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Telefone 1</label>
                                                                <input type="text" className="form-control" placeholder="Insira o telefone 1" value={editingLeiturista.u1_telefone_1} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_telefone_1: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Telefone 2</label>
                                                                <input type="text" className="form-control" placeholder="Insira o telefone 2" value={editingLeiturista.u1_telefone_2} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_telefone_2: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Observação</label>
                                                                <input type="text" className="form-control" placeholder="Insira uma observação" value={editingLeiturista.u1_obs} onChange={(e) => setEditingLeiturista({ ...editingLeiturista, u1_obs: e.target.value })} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </Modal.Body >
                                        <Modal.Footer>
                                            {/* <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button> */}
                                            <Button variant="primary" onClick={updateLeiturista}>
                                                Atualizar
                                            </Button>
                                        </Modal.Footer>
                                    </Modal >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </div >
    )
}

export default CadastroLeiturista;