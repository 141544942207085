import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { formatDate } from './../../convert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import api from "../../services/api";
import SideBar from './../SideBar';

// import VMoney from 'v-money';

const Regioes = () => {
    const [formulario, setFormulario] = useState(true);
    const [loading, setLoading] = useState(false);
    const [modalidades, setModalidades] = useState([]);
    const [loterias, setLoterias] = useState([]);
    const [cotacoes, setCotacoes] = useState([]);
    const [descargas, setDescargas] = useState([]);
    const [regioes, setRegioes] = useState([]);
    const [regiao, setRegiao] = useState({
        nome: '',
        codigo: '',
        descricao: '',
        loterias: [],
        modalidades: []
    });
    const [selecionado, setSelecionado] = useState({});
    const [selecionadoM, setSelecionadoM] = useState({});

    // const [loteriaAtiva, setLoteriaAtiva] = useState([]);
    const [loteriaCotacao, setLoteriaCotacao] = useState([]);
    const [loteriaDescarga, setLoteriaDescarga] = useState([]);
    // const [modalidadeAtiva, setModalidadeAtiva] = useState([]);


    const [modalNew, setModalNew] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);

    const handleCloseCad = () => setModalNew(false);
    const handleCloseUp = () => setModalEdit(false);
    const handleShowCad = () => setModalNew(true);
    const handleShowUp = () => setModalEdit(true);

    const handleLoteriaCotacao = (loteria, cotacaoId) => {
        setLoterias(prevLoterias => prevLoterias.map(prevLoteria => {
            if (prevLoteria.l1_id === loteria.l1_id) {
                return {
                    ...prevLoteria,
                    selecionado: {
                        ...prevLoteria.selecionado,
                        ativa: true,
                        cotacao: cotacaoId, // Definir a cotacaoId para esta loteria específica
                    }
                };
            }
            return prevLoteria;
        }));
    };


    const handleLoteriaDescarga = (loteria, descargaId) => {
        setLoterias(prevLoterias => prevLoterias.map(prevLoteria => {
            if (prevLoteria.l1_id === loteria.l1_id) {
                return {
                    ...prevLoteria,
                    selecionado: {
                        ...prevLoteria.selecionado,
                        ativa: true,
                        descarga: descargaId, // Definir a descargaId para esta loteria específica
                    }
                };
            }
            return prevLoteria;
        }));
    };


    const setModalidadeAtiva = (modalidade, isChecked) => {
        setModalidades(prevModalidades => prevModalidades.map(prevModalidade => {
            if (prevModalidade.id === modalidade.id) {
                return {
                    ...prevModalidade,
                    selecionadoM: {
                        ...prevModalidade.selecionadoM,
                        ativa: isChecked,
                    }
                };
            }
            return prevModalidade;
        }));
    };

    const setLoteriaAtiva = (loteria, isChecked) => {
        setLoterias(prevLoterias => prevLoterias.map(prevLoteria => {
            if (prevLoteria.l1_id === loteria.l1_id) {
                return {
                    ...prevLoteria,
                    selecionado: {
                        ...prevLoteria.selecionado,
                        ativa: isChecked,
                        cotacao: null,
                        descarga: null,
                    }
                };
            }
            return prevLoteria;
        }));
    };


    useEffect(() => {
        loadModalidades();
        loadLoterias();
        loadCotacoes();
        loadDescargas();
        loadRegioes();
    }, []);

    const loadModalidades = () => {
        api.get(`/listModalidades`)
            .then(response => {
                setModalidades(response.data.modalidades.map(modalidade => ({
                    ...modalidade,
                    selecionadoM: {
                        ativa: true,
                    },
                })));
            })
            .catch(error => console.log(error))
            .finally(() => setFormulario(true));
    };

    const loadLoterias = () => {
        api.get(`/listLoterias`)
            .then(response => {
                setLoterias(response.data.loterias.map(loteria => ({
                    ...loteria,
                    selecionado: {
                        ativa: false,
                        cotacao: null,
                        descarga: null,
                    },
                })));
            })
            .catch(error => console.log(error))
            .finally(() => setFormulario(true));
    };

    const loadCotacoes = () => {
        api.get(`/listCotacoes`)
            .then(response => {
                setCotacoes(response.data.cotacoes);
            })
            .catch(error => console.log(error))
            .finally(() => setFormulario(true));
    };

    const loadDescargas = () => {
        api.get(`/listDescargas`)
            .then(response => {
                setDescargas(response.data.descargas);
            })
            .catch(error => console.log(error))
            .finally(() => setFormulario(true));
    };

    const loadRegioes = () => {
        setLoading(true);
        api.get(`/listRegioes`)
            .then(response => {
                setRegioes(response.data.regioes);
            })
            .catch(error => console.log(error))
            .finally(() => {
                setLoading(false);
                setFormulario(true);
            });
    };

    useEffect(() => {
        // Verifica se ambas modalidades e loterias foram carregadas
        // if (modalidades.length > 0 && loterias.length > 0) {
        atualizarRegiao();
        // }
    }, [modalidades, loterias]);


    const atualizarRegiao = () => {
        const dadosLoterias = loterias
            .filter(loteria => loteria.selecionado.ativa)
            .map(loteria => ({
                id_loteria: loteria.l1_id,
                ativa: loteria.selecionado.ativa,
                id_cotacao: loteria.selecionado.cotacao,
                id_descarga: loteria.selecionado.descarga
            }));

        const dadosModalidades = modalidades
            .filter(modalidade => modalidade.selecionadoM.ativa)
            .map(modalidade => ({
                id: modalidade.id,
                nome: modalidade.nome,
                tipo: modalidade.tipo,
                ativa: modalidade.ativa,
                nivel: modalidade.nivel,
                sigla: modalidade.sigla,
                numMax: modalidade.numMax,
                tipoPalpite: modalidade.tipoPalpite,
            }));

        setRegiao(prevState => ({
            ...prevState,
            loterias: dadosLoterias,
            modalidades: dadosModalidades
        }));
    };
    const sendRegiao = () => {
        const fieldsToCheck = ['id_cotacao', 'id_loteria', 'nome', 'codigo', 'descricao'];
        const nullFields = fieldsToCheck.filter(field => regiao[field] === null || regiao[field] === '');

        // Verificar se há algum valor null nos campos específicos de cada loteria
        const nullLoterias = regiao.loterias.filter(loteria => loteria.id_cotacao === null || loteria.id_descarga === null);

        if (nullFields.length > 0 || nullLoterias.length > 0) {
            // if (hasNullValue) {
            // Se houver algum valor null, exibir um alerta
            Swal.fire({
                icon: 'error',
                title: 'Há valores nulos na região!',
                text: 'Por favor, preencha todos os campos antes de cadastrar a região.',
                timer: 70000,
                timerProgressBar: true,

            });
            return;

        }
        api.post(`/cadRegiao`, regiao)
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Região cadastrada com sucesso',
                    timer: 7000,
                    timerProgressBar: true,
                });
                closeModal();
                loadRegioes();
            })
            .catch(error => {
                console.log(error);
                Swal.fire({
                    icon: 'warning',
                    title: 'Houve algum erro!',
                    timer: 7000,
                    timerProgressBar: true,
                });
            });
    };

    const closeModal = () => {
        setRegiao({
            nome: '',
            codigo: '',
            descricao: '',
            loterias: [],
            modalidades: [],
        });
        setSelecionado(null);
    };

    const deleteRegiao = (r1_id) => {
        Swal.fire({
            title: 'Realmente deseja deletar?',
            text: 'Região será deletada permanentemente!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                api.post(`/cancelarRegiao${r1_id}`)
                    .then(() => {
                        loadCotacoes();
                        Swal.fire({
                            icon: 'success',
                            title: 'Região deletada com sucesso',
                            timer: 7000,
                            timerProgressBar: true,
                        });
                    })
                    .catch(() => {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Região associada a Cambistas!',
                            timer: 7000,
                            timerProgressBar: true,
                        });
                    })
                    .finally(() => {
                        setFormulario(true);
                        setRegiao({});
                        loadRegioes();
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Região não deletada',
                    icon: 'error'
                });
            }
        });
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Regiões <span className="sub-header-quote">cadastro de Região</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-dark new-quote-btn" onClick={handleShowCad}>
                                            + Nova Região
                                        </button>
                                    </div>
                                    <main>
                                            <div>
                                                <div className="box box-primary">
                                                    <div className="content">
                                                        <div className="box-header with-border">
                                                            <div className="row">
                                                                <div className="box-body">
                                                                    <div className="table-responsive">
                                                                        <table className="table table-bordered table-hover">
                                                                            <thead className="tabela-class">
                                                                                <tr>
                                                                                    <th className="tabela-class">NOME</th>
                                                                                    <th className="tabela-class">Codigo</th>
                                                                                    <th className="tabela-class">Q. LOTERIAS</th>
                                                                                    <th className="tabela-class">Q. CAMBISTAS</th>
                                                                                    <th className="tabela-class">DATA CRIADO</th>
                                                                                    <th className="tabela-class">EDITAR</th>
                                                                                    <th className="tabela-class">DETALHAR</th>
                                                                                    <th className="tabela-class">DELETAR</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="tabela-class">
                                                                                {regioes.map(regioe => (
                                                                                    <tr key={regioe.r1_id}>
                                                                                        <td>{regioe.r1_nome}</td>
                                                                                        <td>{regioe.r1_codigo}</td>
                                                                                        {/* <td>{regioe.loteriasCount[0].id_loteria.length}</td> */}
                                                                                        {/* <td>{regioe.loteriasCount && regioe.loteriasCount.length > 0 ? regioe.loteriasCount[0].id_loteria.length : 0}</td> */}
                                                                                        <td>
                                                                                            {regioe && regioe.loteriasCount && regioe.loteriasCount.length > 0 && regioe.loteriasCount[0] && regioe.loteriasCount[0].id_loteria ? regioe.loteriasCount[0].id_loteria.length : 0}
                                                                                        </td>

                                                                                        <td>{regioe.cambistasCount}</td>
                                                                                        <td>{formatDate(regioe.created_at)}</td>
                                                                                        <td><button className="btn btn-primary"><FontAwesomeIcon icon={faEdit} /></button></td>
                                                                                        <td><button className="btn btn-primary"><FontAwesomeIcon icon={faEdit} /></button></td>
                                                                                        <td><button type="button" className="btn btn-danger" onClick={() => deleteRegiao(regioe.r1_id)}><FontAwesomeIcon icon={faTrash} /></button></td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                        <scale-loader loading={loading} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        <Modal show={modalNew} onHide={handleCloseCad} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>Nova Região</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <div className="container">
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 modal-label">
                                                                <label htmlFor="nomeLoteria">Nome da Região:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input value={regiao.nome} onChange={(e) => setRegiao({ ...regiao, nome: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 modal-label">
                                                                <label htmlFor="siglaLoteria">Codigo:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input value={regiao.codigo} onChange={(e) => setRegiao({ ...regiao, codigo: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 modal-label">
                                                                <label htmlFor="siglaLoteria">Descrição:</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input value={regiao.descricao} onChange={(e) => setRegiao({ ...regiao, descricao: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="dias-semana-editar">
                                                        <div className="dia">
                                                            <div className="row mb-3">
                                                                <div className="form-check">
                                                                    <div className="dias-semana-editar">
                                                                        <div className="dia">
                                                                            <div className="row mb-3">
                                                                                <div className="form-check">
                                                                                    <table className="table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col">ATIVA</th>
                                                                                                <th scope="col">LOTERIA</th>
                                                                                                <th scope="col">COTAÇÃO</th>
                                                                                                <th scope="col">DESCARGA</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {loterias.map(loteria => (
                                                                                                <tr key={loteria.l1_id}>
                                                                                                    <th scope="row">
                                                                                                        <input className="" type="checkbox"
                                                                                                            checked={loteria.selecionado.ativa}
                                                                                                            onChange={(e) => setLoteriaAtiva(loteria, e.target.checked)} />
                                                                                                    </th>
                                                                                                    <td>{loteria.l1_nome}</td>
                                                                                                    <td>
                                                                                                        <select class="form-control form-control" value={loteria.selecionado.cotacao} onChange={(e) =>
                                                                                                            handleLoteriaCotacao(loteria, e.target.value)}>
                                                                                                            <option desabilited value=''>Cotação</option>
                                                                                                            {cotacoes.map(cotacao => (
                                                                                                                <option key={cotacao.c2_id} value={cotacao.c2_id}>{cotacao.c2_nome}</option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <select class="form-control form-control" value={loteria.selecionado.descarga} onChange={(e) => handleLoteriaDescarga(loteria, e.target.value)}>
                                                                                                            <option desabilited value=''>Descarga</option>
                                                                                                            {descargas.map(descarga => (
                                                                                                                <option key={descarga.d1_id} value={descarga.d1_id}>{descarga.d1_nome}</option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <table className="table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th scope="col">ATIVA</th>
                                                                                                <th scope="col">Modalidade</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {modalidades.map(modalidade => (
                                                                                                <tr key={modalidade.id}>
                                                                                                    <th scope="row">
                                                                                                        <input type="checkbox"
                                                                                                            checked={modalidade.selecionadoM.ativa}
                                                                                                            onChange={(e) => setModalidadeAtiva(modalidade, e.target.checked)}
                                                                                                        />
                                                                                                    </th>
                                                                                                    <td>{modalidade.nome}</td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={sendRegiao}>
                                                    + CADASTRAR
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>


                                        <Modal show={modalEdit} onHide={handleCloseUp} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>EDITAR COTAÇÃO</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal fade" id="novaCotacao" tabIndex="-1" role="dialog" aria-labelledby="editarModalLabel" aria-hidden="true">
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                {/* <Button variant="primary" onClick={upCotacao}>
                                                + SALVAR
                                            </Button> */}
                                            </Modal.Footer>
                                        </Modal>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Regioes;