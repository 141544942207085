import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Importe Link do React Router
import './Sidebar.css';
import { PiUsersThreeFill, PiNewspaperClippingFill ,PiBankFill } from "react-icons/pi";
import { GiEightBall } from "react-icons/gi";

import { FaNewspaper } from "react-icons/fa";
import { FaHandHoldingDollar, FaListCheck } from "react-icons/fa6";
import { TbReportSearch } from "react-icons/tb";
import { SiStackshare } from "react-icons/si";
import { ImListNumbered } from "react-icons/im";

const SideBar = () => {
    const [isOpen, setIsOpen] = useState(false); 
    const [isOpenJb, setIsOpenJb] = useState(false); 
    const [isOpenRifa, setIsOpenRifa] = useState(false); 
    const [isOpenDezena, setIsOpenDezena] = useState(false); 
    const [isOpenBingo, setIsOpenBingo] = useState(false); 
    const [isOpenCn, setIsOpenCn] = useState(false); 
    const [isOpenCnRelatorios, setIsOpenCnRelatorios] = useState(false); 

    
    const toggleSubMenu = () => {
        setIsOpen(!isOpen); 
    };
    
    const toggleSubMenuJb = () => {
        setIsOpenJb(!isOpenJb); 
    };
    const toggleSubMenuDezena = () => {
        setIsOpenDezena(!isOpenDezena); 
    };
    const toggleSubMenuRifa = () => {
        setIsOpenRifa(!isOpenRifa); 
    };
    const toggleSubMenuBingo = () => {
        setIsOpenBingo(!isOpenBingo); 
    };
    const toggleSubMenuCn = () => {
        setIsOpenCn(!isOpenCn); 
    };

    const toggleSubMenuCnRelatorios = () => {
        setIsOpenCnRelatorios(!isOpenCnRelatorios); 
    };

    const showJB = process.env.REACT_APP_API_JB === 'true';
    const showBingo = process.env.REACT_APP_API_BINGO === 'true';
    const showRifa = process.env.REACT_APP_API_RIFA === 'true';
    const showDezena = process.env.REACT_APP_API_DEZENA === 'true';
    const showCn = process.env.REACT_APP_API_CN === 'true';

    return (
        <div className="area">
            <nav className="main-menu">
                <ul>
                    <li>
                        
                        <a href="/Financeiro/Caixa">
                            <i className="fa fa-report fa-2x"><PiBankFill/></i>

                            <span className="nav-text">
                               Financeiro
                            </span>
                        </a>
                    </li>
                    <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenu}>
                            <i className="fa fa-report fa-2x"><PiUsersThreeFill /></i>
                            <span className="nav-text">
                                Colaborador
                            </span>
                        </a>
                        {isOpen && (
                            <ul className="sub-menu">
                                <li><Link to="/Cadastro/Gerente">Gerente</Link></li>
                                <li><Link to="/Cadastro/Leiturista">Leiturista</Link></li>
                                <li><Link to="/Cadastro/Cambista">Cambista</Link></li>
                                <li><Link to="/Web/Cliente">Cliente</Link></li>
                                
                            </ul>
                        )}
                    </li>
                    <li>
                        
                        <a href="/Web/Anuncios">
                            <i className="fa fa-report fa-2x"><PiBankFill/></i>
                            <span className="nav-text">
                               Anuncios WEB
                            </span>
                        </a>
                    </li>
                    <li>
                    <a href="/Lancamentos" >
                            <i className="fa fa-report fa-2x"><FaHandHoldingDollar /></i>
                            <span className="nav-text">
                            Lancamentos
                            </span>
                        </a>
                    </li>
                    {showCn && <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenuCn}> {/* Adicione um evento onClick para abrir ou fechar o submenu */}
                            <i className="fa fa-globe fa-2x"></i>
                            <span className="nav-text">
                                C.N
                            </span>
                        </a>
                        {isOpenCn && (
                            <ul className="sub-menu">
                                <li><Link to="/Cn/Loja">Cadastrar Loja</Link></li>
                                <li><Link to="/Cn/Equipamento">Cadastrar Equipamentos</Link></li>

                                <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenuCnRelatorios}> {/* Adicione um evento onClick para abrir ou fechar o submenu */}
                            <i className="fa fa-glob fa-2x"></i>
                            <span className="nav-text">
                               C.N Relatorios 
                            </span>
                        </a>
                        {isOpenCnRelatorios && (
                            <ul className="sub-menu">
                                <li><Link to="/Cn/Relatorios/Diario">Acerto Diario</Link></li>
                                <li><Link to="/Cn/Relatorios/Periodo">Acerto por periodo</Link></li>
                                <li><Link to="/Cn/Relatorios/MovLojas">Movimento Lojas</Link></li>
                                <li><Link to="/Cn/Relatorios/MovGerente">Movimento Gerente</Link></li>
                                <li><Link to="/Cn/Relatorios/RecLojas">Recebidos Lojas</Link></li>
                                <li><Link to="/Cn/Relatorios/RecGerente">Recebidos Gerente</Link></li>

                            </ul>
                        )}
                    </li>

                            </ul>
                        )}
                    </li>}

                  { showJB && <>
                    <li>
                        <a href="/Resultados">
                            <i className="fa fa-report fa-2x"><FaListCheck/></i>
                            <span className="nav-text">
                            Resultados
                            </span>
                        </a>
                    </li>
                 
                  <li>
                        <a href="/Cadastro/Cotacoes">
                            <i className="fa fa-report fa-2x"><ImListNumbered/></i>
                            <span className="nav-text">
                               Cotações
                            </span>
                        </a>
                    </li>
                    
                    <li>
                        <a href="/Loterias">
                            <i className="fa fa-report fa-2x"><FaNewspaper /></i>
                            <span className="nav-text">
                               Loterias
                            </span>
                        </a>
                    </li>
                    
                    <li>
                        <a href="/Regioes">
                            <i className="fa fa-globe fa-2x"></i>
                            <span className="nav-text">
                            Regiões
                            </span>
                        </a>
                    </li>

                    <li>
                        <a href="/Descarga">
                            <i className="fa fa-report fa-2x"><SiStackshare/></i>

                            <span className="nav-text">
                            Descarga
                            </span>
                        </a>
                    </li>
                    </>
                    }
                   
                    
                    <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenuRifa}> {/* Adicione um evento onClick para abrir ou fechar o submenu */}
                            <i className="fa fa-report fa-2x"><TbReportSearch/></i>
                            <span className="nav-text">
                                Relatorios
                            </span>
                        </a>
                        {isOpenRifa && (
                            <ul className="sub-menu">
                                <li><Link to="/Relatorio/Geral">Geral Cambista</Link></li>
                                <li><Link to="/Rifa/Resultado">Resultados Rifa</Link></li>
                            </ul>
                        )}
                    </li>
                  
                    <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenu}>
                            <i className="fa fa-report"><PiNewspaperClippingFill /></i>
                            <span className="nav-text">
                                Pule
                            </span>
                        </a>
                        {isOpen && (
                            <ul className="sub-menu">
                                <li><Link to="/Pule/Jb">J.B</Link></li>
                                <li><Link to="/Pule/Rifa">RIFA</Link></li>
                                <li><Link to="/Pule/Bingo">BINGO</Link></li>
                                <li><Link to="/Pule/Bolao-Dezenas">BOLÃO DE DEZENAS</Link></li>
                            </ul>
                        )}
                    </li>
                  
                    {showRifa && <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenuRifa}> {/* Adicione um evento onClick para abrir ou fechar o submenu */}
                            <i className="fa fa-globe fa-2x"></i>
                            <span className="nav-text">
                                Rifa
                            </span>
                        </a>
                        {isOpenRifa && (
                            <ul className="sub-menu">
                                <li><Link to="/Rifa/Cadastro/Rifa">Cadastrar Rifa</Link></li>
                                <li><Link to="/Rifa/Resultado">Resultados Rifa</Link></li>
                            </ul>
                        )}
                    </li>}
                   {showBingo && <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenuBingo}> {/* Adicione um evento onClick para abrir ou fechar o submenu */}
                            <i className="fa fa-report"><GiEightBall/></i>
                            <span className="nav-text">
                                Bingo
                            </span>
                        </a>
                        {isOpenBingo && (
                            <ul className="sub-menu">
                                <li><Link to="/Bingo/Cadastro/Bingo">Cadastrar Bingo</Link></li>
                                <li><Link to="/Bingo/Resultado">Resultados Bingo</Link></li>
                                <li><Link to="/Bingo/Relatorio">Relatorios Bingo</Link></li>
                            </ul>
                        )}
                    </li>}

                    {showDezena && <li className="has-subnav">
                        <a href="#" onClick={toggleSubMenuDezena}> {/* Adicione um evento onClick para abrir ou fechar o submenu */}
                            <i className="fa fa-globe fa-2x"></i>
                            <span className="nav-text">
                                Dezena
                            </span>
                        </a>
                        {isOpenDezena && (
                            <ul className="sub-menu">
                                <li><Link to="/Rifa/Cadastro/Rifa">Cadastrar Rifa</Link></li>
                                <li><Link to="/Rifa/Resultado">Resultados Rifa</Link></li>
                            </ul>
                        )}
                    </li>}
                </ul>
            </nav>
        </div>
    );
};

export default SideBar;
// 249