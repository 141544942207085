import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../services/api";
import {
  getCookieFromBrowser,
  removeCookie,
  setCookie,
} from "../services/cookies";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [isRequireAuth, setIsRequireAuth] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);


  const navigate = useNavigate()
  

  useEffect(() => {
    async function loadUserFromCookies() {
      // const token = getCookieFromBrowser("token");
      const token = localStorage.getItem("token");
      if (token) {
        try {
          api.defaults.headers.Authorization = `Bearer ${token}`;
          // setUser({ id: 1 }); // Simulate user data, replace with actual logic
          setAuthenticated(true);
        } catch (e) {
          localStorage.removeItem("token");
          // removeCookie("token");
          setUser(null);
          setAuthenticated(false);
        }
      } else {
        setUser(null);
        setAuthenticated(false);
      }
    }
    loadUserFromCookies();
  }, []);

  const checkIsAuthenticated = () => {
    return authenticated;
  };
  
  // const requireAuth = (callback = null) => {
  //   if(isAuthenticated && callback){ 
  //     //fazer logica no compoennt de pegar o token ou redirecionar caso false
  //     callback();
  //     console.log('usuaratio antutica função cutomizada')
  //   }else if(isAuthenticated){ 
  //     //fazer logica no compoennt de pegar o token ou redirecionar caso false, função padrão
  //     console.log('usuaratio antutica sem função cutomizada')
  //   }
  // }
  const requireAuth = (callback = null) => {
    if (checkIsAuthenticated() && callback) {
      callback();
    } else if (checkIsAuthenticated()) {
    }
  };

  // const navigate = useNavigate();
  const login = async (email, password, 
    setErrorMessage,
    //  setError
     ) => {

    try {
      const { data: response } = await api.post("/loginColaborador", {
        login: email,
        senha: password,
      });
      const token = response.token;
      if (token) {
        console.log('cheguei mas essa porra nao redireciona')
        localStorage.setItem('token', token);
        setAuthenticated(true);
        setCookie("token", token);
        api.defaults.headers.Authorization = `Bearer ${token}`;
        // setUser({ id: 1 }); 
        // return redirect("/home");
        //  navigate("/");
         window.location.href = '/';

      }
    } catch (err) {
      setError(true);
      setErrorMessage(err.response.data.message);
    }
  };

  const logout = () => {
    removeCookie("token");
    setUser(null);
    setAuthenticated(false);
  };

  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  return (
    <AuthContext.Provider
      value={{
        // isAuthenticated: !!user,
        // isAuthenticated,
        isAuthenticated: checkIsAuthenticated(),
        user,
        login,
        logout,
        requireAuth,
        setIsRequireAuth,
        isRequireAuth,
        setErrorMessage,
        errorMessage
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
