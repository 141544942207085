import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from "../../services/api";
import SideBar from './../SideBar';
import ScaleLoader from "react-spinners/ScaleLoader";

function CadRifa() {
    const [rifas, setRifas] = useState([]);
    const [formData, setFormData] = useState({
        raffleName: '',
        description: '',
        ticketPrice: '',
        inicial: '',
        final: '',
        image: '',
        name: '',
        rankingP: '',
        rankingN: '',
        numerosPremiados:[],
    });
    const [loading, setLoading] = useState(false);
    const [newRifa, setNewRifa] = useState(false);
    const [numeroPremiado, setNumeroPremiado] = useState('');

    const adicionarNumeroPremiado = () => {
        if (numeroPremiado !== '') {
            setFormData({ ...formData, numerosPremiados: [...formData.numerosPremiados, parseInt(numeroPremiado)] });
            setNumeroPremiado('');
        }
    };
    const handleShowCad = () => setNewRifa(true);
    const handleCloseCad = () => setNewRifa(false);


    useEffect(() => {
        loadRifas();
    }, []);

    const loadRifas = () => {
        setLoading(true);
        api.get(`/listRifas`)
            .then((response) => {
                setRifas(response.data.rifas);
            })
            .catch(error => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleImageChange = (event) => {
        const image = event.target.files[0];
        setFormData({ ...formData, image, name: image.name });
    };

    const submitRaffle = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await api.post(`/cadastroRifa`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Rifa cadastrada com sucesso!');
            setNewRifa(false)
        } catch (error) {
            console.error('Erro ao cadastrar rifa:', error);
            alert('Erro ao cadastrar rifa. Verifique o console para mais detalhes.');
        } finally {
            setLoading(false);
        }
    };

    const ativarDesativar = async (rifaId, ativo) => {
        try {
            await api.put(`/ativarDesativarRifa/${rifaId}`, { ativo });
            loadRifas()
        } catch (error) {
            // Lidar com erros
        }
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                {/* <ScaleLoader color={"#000"} loading={loading} /> */}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Rifas
                                        <span className="sub-header-quote">Cadastro de Rifas</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShowCad}>
                                            + Nova Rifa
                                        </button>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <div className="box box-primary">
                                                <div className="content">
                                                    <div className="box-header with-border">
                                                        <div className="row">
                                                            <div className="box-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-hover">
                                                                        <thead className="tabela-class">
                                                                            <tr>
                                                                                <th className="tabela-class">NOME</th>
                                                                                <th className="tabela-class">DISPONIVEL</th>
                                                                                <th className="tabela-class">STATUS</th>
                                                                                <th className="tabela-class">DATA CRIADO</th>
                                                                                <th className="tabela-class">ABRIR/FECHAR</th>
                                                                                <th className="tabela-class">EDITAR</th>
                                                                                {/* <th className="tabela-class">DETALHAR</th> */}
                                                                                <th className="tabela-class">DELETAR</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="tabela-class">
                                                                            {rifas.map((rifa) => (
                                                                                <tr key={rifa.id}>
                                                                                    <td>{rifa.nome}</td>
                                                                                    <td>{rifa.disponivel}</td>
                                                                                    <td>{rifa.aberto === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}</td>
                                                                                    <td>{new Date(rifa.criado_em).toLocaleDateString('pt-BR')}</td>
                                                                                    {/* <td><button className="btn btn-primary" onClick={() => openEditModal(rifa)}><i className="fa fa-edit"></i></button></td> */}
                                                                                    {rifa.aberto === 1 ? <td><button className="btn btn-warning" onClick={() => ativarDesativar(rifa.id, 0)}><i className="fa fa-times"></i></button></td>
                                                                                        : <td><button className="btn btn-success" onClick={() => ativarDesativar(rifa.id, 1)}><i className="fa fa-check"></i></button></td>}
                                                                                    <td><button className="btn btn-primary" data-bs-target="#editRifa"><i className="fa fa-edit"></i></button></td>
                                                                                    <td><button className="btn btn-danger"><i className="fa fa-trash"></i></button></td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                    <ScaleLoader color={"#000"} loading={loading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Modal show={newRifa} onHide={handleCloseCad} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Nova Rifa</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="modal-body">
                                                <div className="centralizar">
                                                    <div className="container">
                                                        <form onSubmit={submitRaffle} className="form">
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-prepend">
                                                                    <label htmlFor="raffleName">Nome da Rifa:</label>
                                                                    <input type="text" id="raffleName" className="form-control" value={formData.raffleName} onChange={(e) => setFormData({ ...formData, raffleName: e.target.value })} required />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="description">Descrição:</label>
                                                                <textarea id="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} className="form-control" required></textarea>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="ticketPrice">Preço do Bilhete (em R$):</label>
                                                                <input type="number" value={formData.ticketPrice} onChange={(e) => setFormData({ ...formData, ticketPrice: e.target.value })} step="0.01" id="ticketPrice" className="form-control" required />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="range">Faixa de Números Disponíveis:</label>
                                                                <div className="row">
                                                                    <div className="col-md-4 mb-3"> 
                                                                        <div className="input-group">
                                                                            <input type="number" value={formData.inicial} onChange={(e) => setFormData({ ...formData, inicial: e.target.value })} id="inicial" className="form-control" required />
                                                                        </div>
                                                                    </div>

                                                                    <span className="range-divider">a</span>
                                                                    <div className="col-md-4 mb-3"> 
                                                                        <div className="input-group">
                                                                            <input type="number" value={formData.final} onChange={(e) => setFormData({ ...formData, final: e.target.value })} id="final" className="form-control" required />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            {/* <div className="form-group"> */}
                                                                <label htmlFor="range">Quant. ranking:</label>
                                                                <div className="row">
                                                                        <span>Positivo</span>
                                                                    <div className="col-md-6 mb-5"> 
                                                                        <div className="input-group">
                                                                            <input type="number" value={formData.rankingP} onChange={(e) => setFormData({ ...formData, rankingP: e.target.value })} id="rankingP" className="form-control" required />
                                                                        </div>
                                                                    {/* </div>

                                                                    <div className="col-md-4 mb-3">  */}
                                                                            <span>Negativo</span>
                                                                        <div className="input-group">
                                                                            <input type="number" value={formData.rankingN} onChange={(e) => setFormData({ ...formData, rankingN: e.target.value })} id="rankingN" className="form-control" required />
                                                                        </div>
                                                                    </div>

                                                                {/* </div> */}
                                                                <div className="form-group">
    <label htmlFor="novoNumero">Adicionar Número Premiado:</label>
    <div className="input-group">
        <input type="number" value={numeroPremiado} onChange={(e) => setNumeroPremiado(e.target.value)} id="novoNumero" className="form-control" />
        <button onClick={adicionarNumeroPremiado} className="btn btn-primary ml-2">Adicionar</button>
    </div>
    <ul>
        {formData.numerosPremiados.map((numero, index) => (
            <li key={index}>{numero}</li>
        ))}
    </ul>
</div>
                                                            </div>
                                                            
                                                            <div className="form-group">
                                                                <label htmlFor="image">Imagem da Rifa:</label>
                                                                <input type="file" onChange={handleImageChange} className="custom-file-input" accept="image/*" required />
                                                            </div>
                                                            {/* <button type="submit" className="button">Cadastrar Rifa</button> */}
                                                        </form>
                                                    </div>


                                                </div>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={submitRaffle}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CadRifa;

