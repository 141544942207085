import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './../../convert';
import './../styles/Global.css';
import SideBar from './../SideBar';
import RingLoader from 'react-spinners/RingLoader';

const CadastroGerente = () => {
    const [gerentes, setGerentes] = useState([]);
    const [gerente, setGerente] = useState({
        comissao_jb: 0,
        comissao_rifa: 0,
        comissao_bingo: 0,
        comissao_dezena: 0,
        tipo_comissao_jb: true,
        tipo_comissao_rifa: true,
        tipo_comissao_bingo: true,
        tipo_comissao_dezena: true,
        login: '',
        senha: '',
        codigo: '',
        salario: '',
        salarioData: '',
        nome: '',
        endereco: '',
        cep: '',
        telefone1: '',
        telefone2: '',
        obs: ''
    });
    const [show, setShow] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingGerente, setEditingGerente] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadGerentes();
    }, []);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleUpdateClose = () => setShowEditModal(false);

    const handleUpdateShow = (gerente) => {
        gerente.u1_senha = null;
        setEditingGerente(gerente);
        setShowEditModal(true);
    };

    const loadGerentes = () => {
        setLoading(true)
        api.get(`/listGerentes`)
            .then((response) => {
                setGerentes(response.data.gerentes);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    function updateGerente() {
        api.put(`/updateGerente/${editingGerente.u1_id}`, editingGerente)
            .then(() => {
                setShowEditModal(false);
                loadGerentes();
            })
            .catch((error) => {
                console.log('Erro ao atualizar gerente:', error);
            });
    }

    function sendGerente() {
        api.post(`/cadGerente`, gerente)
            .then(() => {
                loadGerentes();
                setShow(false)
                setGerente([])
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Gerentes
                                        <span className="sub-header-quote">Cadastro de Gerente</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-dark new-quote-btn" onClick={handleShow}>
                                            + Novo Gerente
                                        </button>
                                    </div>
                                    <div>
                                        <div className="box box-primary">
                                            <div className="content">
                                                <div className="box-header with-border">
                                                    <div className="row">
                                                        <div className="box-body">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered table-hover">
                                                                    <thead className="tabela-class">
                                                                        <tr>
                                                                            <th className="tabela-class">NOME</th>
                                                                            <th className="tabela-class">CODIGO</th>
                                                                            <th className="tabela-class">CAIXA</th>
                                                                            <th className="tabela-class">Q. CAMBISTAS</th>
                                                                            <th className="tabela-class">DATA CRIADO</th>
                                                                            <th className="tabela-class">EDITAR</th>
                                                                            {/* <th className="tabela-class">DETALHAR</th> */}
                                                                            <th className="tabela-class">DELETAR</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tabela-class">
                                                                        <>
                                                                            {gerentes.map(gerente => (
                                                                                <tr key={gerente.u1_id}>
                                                                                    <td>{gerente.u1_nome}</td>
                                                                                    <td>{gerente.u1_codigo}</td>
                                                                                    <td>{gerente.u1_caixa}</td>
                                                                                    <td>{gerente.total_cambistas}</td>
                                                                                    <td>{formatDate(gerente.created_at)}</td>
                                                                                    <td> <button className="btn btn-primary" onClick={() => handleUpdateShow(gerente)}>
                                                                                        <i className="fa fa- fa-fw"><FontAwesomeIcon icon={faEdit} /></i>
                                                                                    </button></td>
                                                                                    {/* <td><button className="btn btn-primary">
                                                                                        <i className="fa fa- fa-fw"><FontAwesomeIcon icon={faEdit} /></i>
                                                                                    </button></td> */}
                                                                                    <td><button className="btn btn-danger">
                                                                                        <i className="fa fa- fa-fw"><FontAwesomeIcon icon={faTrash} /></i>
                                                                                    </button></td>
                                                                                </tr>
                                                                            ))}
                                                                        </>
                                                                    </tbody>
                                                                </table>
                                                                < div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <RingLoader
                                                                        loading={loading}
                                                                        size={150}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                        css={{ margin: 'auto' }}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Modal show={show} onHide={handleClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>CADASTRAR GERENTE</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="centralizar">
                                                <div id="app" className="button-container">
                                                    <div className="div1">
                                                        <div className="row mb-3"></div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Login</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o login" value={gerente.login} onChange={(e) => setGerente({ ...gerente, login: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Senha</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o senha" value={gerente.senha} onChange={(e) => setGerente({ ...gerente, senha: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Codigo</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o codigo" value={gerente.codigo} onChange={(e) => setGerente({ ...gerente, codigo: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii --> */}
                                                    <div className="div2">
                                                        <h3> Comissões</h3>
                                                        <div>
                                                            <div className="row">
                                                        {process.env.REACT_APP_API_JB === 'true' && (
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label>J.B</label>
                                                                        <input type="number" className="form-control" placeholder="comissão" value={gerente.comissao_jb} onChange={(e) => setGerente({ ...gerente, comissao_jb: e.target.value })} />
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <input className="form-check-input" type="checkbox" role="switch" checked={gerente.tipo_comissao_jb} onChange={() => setGerente({ ...gerente, tipo_comissao_jb: !gerente.tipo_comissao_jb })} />
                                                                            {/* <label style={{ color: liberacao ? 'blue' : 'blue', marginRight: '100px' }}>
                                                                                {gerente.tipo_comissao_jb ? "Compartilha" : "Não compartilha"}
                                                                            </label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        )}
                                                        {process.env.REACT_APP_API_RIFA === 'true' && (
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>RIFA</label>
                                                                    <input type="number" className="form-control" placeholder="comissão" value={gerente.comissao_rifa} onChange={(e) => setGerente({ ...gerente, comissao_rifa: e.target.value })} />
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <input className="form-check-input" type="checkbox" role="switch" checked={gerente.tipo_comissao_rifa} onChange={() => setGerente({ ...gerente, tipo_comissao_rifa: !gerente.tipo_comissao_rifa })} />
                                                                        {/* <label style={{ color: gerente.tipo_comissao_rifa ? 'blue' : 'blue', marginRight: '100px' }}>
                                                                            {gerente.tipo_comissao_rifa ? "Compartilha" : "Não compartilha"}
                                                                        </label> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        {process.env.REACT_APP_API_BINGO === 'true' && (
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>BINGO</label>
                                                                <input type="number" className="form-control" placeholder="comissão" value={gerente.comissao_bingo} onChange={(e) => setGerente({ ...gerente, comissao_bingo: e.target.value })} />
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <input className="form-check-input" type="checkbox" role="switch" checked={gerente.tipo_comissao_bingo} onChange={() => setGerente({ ...gerente, tipo_comissao_bingo: !gerente.tipo_comissao_bingo })} />
                                                                    {/* <label style={{ color: liberacao ? 'blue' : 'blue', marginRight: '100px' }}>
                                                                        {gerente.tipo_comissao_bingo ? "Compartilha" : "Não compartilha"}
                                                                    </label> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )}
                                                        {process.env.REACT_APP_API_DEZENA === 'true' && (
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>DEZENA</label>
                                                                <input type="number" className="form-control" placeholder="comissão" value={gerente.comissao_dezena} onChange={(e) => setGerente({ ...gerente, comissao_dezena: e.target.value })} />
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <input className="form-check-input" type="checkbox" role="switch" checked={gerente.tipo_comissao_dezena} onChange={() => setGerente({ ...gerente, tipo_comissao_dezena: !gerente.tipo_comissao_dezena })} />
                                                                    {/* <label style={{ color: liberacao ? 'blue' : 'blue', marginRight: '100px' }}>
                                                                        {gerente.tipo_comissao_bingo ? "Compartilha" : "Não compartilha"}
                                                                    </label> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Salario
                                                                Fixo</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Insira o nome"
                                                                value={gerente.salario} onChange={(e) => setGerente({ ...gerente, salario: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Data Salario</label>
                                                            <select className="form-control" value={gerente.salarioData} onChange={(e) => setGerente({ ...gerente, salarioData: e.target.value })}>
                                                                {[...Array(29)].map((_, index) => (
                                                                    <option key={index + 1} value={index + 1}>{index + 1}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3> Dados pessoais</h3>
                                            <div>
                                                <div className="row mb-3">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Nome</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o nome"
                                                                    value={gerente.nome} onChange={(e) => setGerente({ ...gerente, nome: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="exampleInputEmail1">Endereço</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o Endereço"
                                                                    value={gerente.endereco} onChange={(e) => setGerente({ ...gerente, endereco: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">CEP</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o CEP"
                                                                    value={gerente.cep} onChange={(e) => setGerente({ ...gerente, cep: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Telefone
                                                                    1</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o Telefone 1"
                                                                    value={gerente.telefone1} onChange={(e) => setGerente({ ...gerente, telefone1: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Telefone
                                                                    2</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira o Telefone 2"
                                                                    value={gerente.telefone2} onChange={(e) => setGerente({ ...gerente, telefone2: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Obs:</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Insira uma observação"
                                                                    value={gerente.obs} onChange={(e) => setGerente({ ...gerente, obs: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </Modal.Body >
                                        <Modal.Footer>
                                            {/* <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button> */}
                                            <Button variant="primary" onClick={sendGerente}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal >

                                    <Modal show={showEditModal} onHide={handleUpdateClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>atualizar GERENTE</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="centralizar">
                                                <div id="app" className="button-container">
                                                    <div className="div1">
                                                        <div className="row mb-3"></div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Login</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o login" value={editingGerente.u1_login} onChange={(e) => setEditingGerente({ ...editingGerente, u1_login: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Senha</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o senha" value={editingGerente.u1_senha} onChange={(e) => setEditingGerente({ ...editingGerente, u1_senha: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>Código</label>
                                                                    <input type="text" className="form-control" placeholder="Insira o código" value={editingGerente.u1_codigo} onChange={(e) => setEditingGerente({ ...editingGerente, u1_codigo: e.target.value })} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div2">
                                                        <h3> Comissões</h3>
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label>J.B</label>
                                                                        <input type="number" className="form-control" placeholder="comissão" value={editingGerente.u1_comissao_jb} onChange={(e) => setEditingGerente({ ...editingGerente, u1_comissao_jb: e.target.value })} />
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <input className="form-check-input" type="checkbox" role="switch" checked={gerente.tipo_comissao_jb} onChange={() => setGerente({ ...gerente, tipo_comissao_jb: !gerente.tipo_comissao_jb })} />
                                                                            {/* <label style={{ color: liberacao ? 'blue' : 'blue', marginRight: '100px' }}>
                                                                                {gerente.tipo_comissao_jb ? "Compartilha" : "Não compartilha"}
                                                                            </label> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <label>RIFA</label>
                                                                    <input type="number" className="form-control" placeholder="comissão" value={editingGerente.u1_comissao_rifa} onChange={(e) => setEditingGerente({ ...editingGerente, u1_comissao_rifa: e.target.value })} />
                                                                    <div style={{ textAlign: 'center' }}>
                                                                        <input className="form-check-input" type="checkbox" role="switch" checked={gerente.tipo_comissao_rifa} onChange={() => setGerente({ ...gerente, tipo_comissao_rifa: !gerente.tipo_comissao_rifa })} />
                                                                        <label style={{ color: gerente.tipo_comissao_rifa ? 'blue' : 'blue', marginRight: '100px' }}>
                                                                            {gerente.tipo_comissao_rifa ? "Compartilha" : "Não compartilha"}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>BINGO</label>
                                                                <input type="number" className="form-control" placeholder="comissão" value={editingGerente.u1_comissao_bingo} onChange={(e) => setEditingGerente({ ...editingGerente, u1_comissao_bingo: e.target.value })} />
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <input className="form-check-input" type="checkbox" role="switch" checked={gerente.tipo_comissao_bingo} onChange={() => setGerente({ ...gerente, tipo_comissao_bingo: !gerente.tipo_comissao_bingo })} />
                                                                    {/* <label style={{ color: liberacao ? 'blue' : 'blue', marginRight: '100px' }}>
                                                                        {gerente.tipo_comissao_bingo ? "Compartilha" : "Não compartilha"}
                                                                    </label> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Salário Fixo</label>
                                                            <input type="text" className="form-control" placeholder="Insira o salário" value={editingGerente.u1_salario} onChange={(e) => setEditingGerente({ ...editingGerente, u1_salario: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <label>Data Salario</label>
                                                            <select className="form-control" value={editingGerente.u1_dia_salario} onChange={(e) => setEditingGerente({ ...editingGerente, u1_dia_salario: e.target.value })}>
                                                                {[...Array(29)].map((_, index) => (
                                                                    <option key={index + 1} value={index + 1}>{index + 1}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3> Dados pessoais</h3>
                                            <div>
                                                <div className="row mb-3">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Nome</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={editingGerente.u1_nome} onChange={(e) => setEditingGerente({ ...editingGerente, u1_nome: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Endereço</label>
                                                                <input type="text" className="form-control" placeholder="Insira o endereço" value={editingGerente.u1_endereco} onChange={(e) => setEditingGerente({ ...editingGerente, u1_endereco: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>CEP</label>
                                                                <input type="text" className="form-control" placeholder="Insira o CEP" value={editingGerente.u1_cep} onChange={(e) => setEditingGerente({ ...editingGerente, u1_cep: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Telefone 1</label>
                                                                <input type="text" className="form-control" placeholder="Insira o telefone 1" value={editingGerente.u1_telefone_1} onChange={(e) => setEditingGerente({ ...editingGerente, u1_telefone_1: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Telefone 2</label>
                                                                <input type="text" className="form-control" placeholder="Insira o telefone 2" value={editingGerente.u1_telefone_2} onChange={(e) => setEditingGerente({ ...editingGerente, u1_telefone_2: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Observação</label>
                                                                <input type="text" className="form-control" placeholder="Insira uma observação" value={editingGerente.u1_obs} onChange={(e) => setEditingGerente({ ...editingGerente, u1_obs: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body >
                                        <Modal.Footer>
                                            {/* <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button> */}
                                            <Button variant="primary" onClick={updateGerente}>
                                                Atualizar
                                            </Button>
                                        </Modal.Footer>
                                    </Modal >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        </div >
    )
}

export default CadastroGerente;
// /540