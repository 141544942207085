import React, { useState, useEffect } from 'react';
import SideBar from './../SideBar';
import { Modal, Button } from 'react-bootstrap';



import moment from 'moment';
import Swal from 'sweetalert2';
import ScaleLoader from 'react-spinners/ScaleLoader';
import api from "../../services/api";
import '../styles/paginations.css'
import '../styles/filter.css'

import { formatDate } from './../../convert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';

function Pule() {
    const [pules, setPules] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateInicio, setDateInicio] = useState(null);
    const [dateFinal, setDateFinal] = useState(null);

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    const [selectedCambista, setSelectedCambista] = useState('');
    const [selectedGerente, setSelectedGerente] = useState('');
    // Outros estados e funções de carregamento de dados...
    const [gerentes, setGerentes] = useState([]);
    const [cambistas, setCambistas] = useState([]);
    const [modalPule, setModalPule] = useState(false);
    const [puleDetail, setPuleDetail] = useState([]);
    const [modalidades, setModalidades] = useState([]);




    const handleClose = () => setModalPule(false);
    const handleShow = () => setModalPule(true);

    const detail = (pule) => {
        console.table(pule)
        setPuleDetail(pule)
        setModalidades(Array.isArray(pule.p1_modalidades) ? pule.p1_modalidades : [])

        handleShow()
    }


    useEffect(() => {
        loadGerentes();
        loadCambistas();
    }, []);

    const loadCambistas = () => {
        api.get(`/listCambistas`)
            .then((response) => {
                setCambistas(response.data.cambista);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadGerentes = () => {
        api.get(`/listGerentes`)
            .then((response) => {
                setGerentes(response.data.gerentes);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    useEffect(() => {
        loadPules();
        // }, [currentPage]);
    }, [currentPage, dateInicio, dateFinal, selectedCambista, selectedGerente]);


    const loadPules = () => {
        setLoading(true);
        const params = {
            searchTerm: searchTerm,
            dateInicio: dateInicio,
            dateFinal: dateFinal,
            page: currentPage,
            limit: itemsPerPage,
        };
        api.get(`/listPules`, { params })
            .then((response) => {
                setPules(response.data.pules);
                console.table(response.data.pules)
                setTotalPages(response.data.totalPages);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const visualizarPule = (puleId) => {
        // Lógica para visualizar uma pule
    };

    const formatarData = (data) => {
        return moment(data).format('DD/MM/YYYY HH:mm:ss');
    };

    const formatarDataA = (data) => {
        return moment(data).format('DD/MM/YYYY');
    };

    const cancelarPule = (pule) => {
        const id = pule.a1_id;
    
        Swal.fire({
            title: `Tem certeza que deseja cancelar a pule: ${pule.a1_pule}?`,
            text: "Esta ação não pode ser desfeita.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, deletar!"
        }).then((result) => {
            if (result.isConfirmed) {
                api.put(`/cancelarPuleAdm/${id}`)
                    .then(response => {
                        Swal.fire({
                            title: "Deletado!",
                            text: `${pule.a1_pule} foi deletado com sucesso.`,
                            icon: "success"
                        });
                        loadPules();
                    })
                    .catch(error => {
                        Swal.fire({
                            title: "Erro!",
                            text: error.response.data.error || "Ocorreu um erro ao cancelar essa pule.",
                            icon: "error"
                        });
                        // console.error(error);
                    });
            }
        });
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">

                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Pule
                                        <span className="sub-header-quote">Relatórios de Pule</span>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <div className="filter-section">
                                                <div className="filter-item">
                                                    <label htmlFor="dateInicio">Data Inicio:</label>
                                                    <input
                                                        type="date"
                                                        id="dateInicio"
                                                        value={dateInicio}
                                                        onChange={(e) => setDateInicio(e.target.value)}
                                                    />
                                                </div>

                                                <div className="filter-item">
                                                    <label htmlFor="dateFinal">Data Final:</label>
                                                    <input
                                                        type="date"
                                                        id="dateFinal"
                                                        value={dateFinal}
                                                        onChange={(e) => setDateFinal(e.target.value)}
                                                    />
                                                </div>

                                                <div className="filter-item">
                                                    <label htmlFor="selectedCambista">Cambista:</label>
                                                    <select
                                                        id="selectedCambista"
                                                        value={selectedCambista}
                                                        onChange={(e) => setSelectedCambista(e.target.value)}
                                                    >
                                                          <option value={null} disabled>
                                                            Selecione um cambista
                                                        </option>
                                                        <option value={null}>
                                                            Todos
                                                        </option>

                                                        {cambistas.map(cambista => (
                                                            <option key={cambista.c3_id} value={cambista.c3_id}>
                                                                {cambista.c3_nome}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="filter-item">
                                                    <label htmlFor="selectedGerente">Gerente:</label>

                                                    <select
                                                        id="selectedGerente"
                                                        value={selectedGerente}
                                                        onChange={(e) => setSelectedGerente(e.target.value)}
                                                    >
                                                        <option value={null} disabled>
                                                            Selecione um gerente
                                                        </option>
                                                        <option value={null}>
                                                            Todos
                                                        </option>

                                                        {gerentes.map(gerente => (
                                                            <option key={gerente.u1_id} value={gerente.u1_id}>
                                                                {gerente.u1_nome}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="box box-primary">
                                                <div className="content">
                                                    <div className="box-header with-border">
                                                        <div className="row">
                                                            <div className="box-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-hover">
                                                                        <thead className="tabela-class">
                                                                            <tr>
                                                                                <th className="tabela-class">Cambista</th>
                                                                                <th className="tabela-class">CÓDIGO</th>
                                                                                <th className="tabela-class">SITUAÇÃO</th>
                                                                                <th className="tabela-class">VALOR</th>
                                                                                <th className="tabela-class">RETORNO</th>
                                                                                <th className="tabela-class">DATA CRIAÇÃO</th>
                                                                                <th className="tabela-class">DATA SORTEIO</th>
                                                                                <th className="tabela-class">DETALHAR</th>
                                                                                <th className="tabela-class">CANCELAR</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="tabela-class">
                                                                            {pules.map((pule) => (
                                                                                <tr key={pule.p1_pule}>
                                                                                    <td>{pule.c3_nome}</td>
                                                                                    <td>{pule.a1_pule}</td>
                                                                                    <td style={{
                                                                                        backgroundColor: pule.a1_status === 'premiada' ? 'blue' :
                                                                                            pule.a1_status === 'cancelada' ? 'red' :
                                                                                                pule.a1_status === 'finalizada' ? 'green' :
                                                                                                    pule.a1_status === 'aberto' ? 'yellow' :

                                                                                                        'inherit', 
                                                                                        fontWeight: 'bold'  
                                                                                    }}>
                                                                                        {pule.a1_status}
                                                                                    </td>
                                                                                    <td>{pule.a1_entrada}</td>
                                                                                    <td>{pule.a1_saida}</td>
                                                                                    <td>{formatDate(pule.created_at)}</td>
                                                                                    <td>{formatDate(pule.p1_data)}</td>
                                                                                    <td>
                                                                                        <button className="btn btn-primary" onClick={() => detail(pule)}>
                                                                                            <FontAwesomeIcon icon={faSearch} />
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="btn btn-danger" onClick={() => cancelarPule(pule)}>
                                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <ScaleLoader loading={loading} />
                                                                </div>
                                                                <div className="pagination">
                                                                    <button onClick={prevPage} disabled={currentPage === 1}>Anterior</button>
                                                                    <span>Página {currentPage} de {totalPages}</span>
                                                                    <button onClick={nextPage} disabled={currentPage === totalPages}>Próxima</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modalPule} onHide={handleClose} size="sm">
                                        <Modal.Header closeButton>
                                            <Modal.Title>PULE</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                        <div style={{ backgroundColor: '#ffd769', minHeight: '100vh' }}>
                                        <label>--------------------------------------------------</label>

                                            <div>
                                                <label>PULE: {puleDetail.a1_pule}</label>
                                            </div>
                                            <div>
                                                <label>VENDEDOR: {puleDetail.c3_nome}</label>
                                            </div>
                                            <div>
                                            <label>DATA: {puleDetail.created_at}</label>
                                            </div>
                                        <label>--------------------------------------------------</label>
            
                                        {modalidades.map(modalidade => (
                        <div key={modalidade.id_modalidade}>
                            <div>
                                <label>Sigla: {modalidade.sigla}</label>
                            </div>
                            <div>
                                {/* Renderizar outros campos da modalidade, se necessário */}
                            </div>
                        </div>
                    ))}
                                    </div>
       

                                        </Modal.Body>
                                        <Modal.Footer>
                                            {/* <Button variant="primary" 
                                            // onClick={}
                                            >
                                                + EDITAR
                                            </Button> */}
                                        </Modal.Footer>
                                    </Modal>
        </div>
    );
}

export default Pule;
