import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import api from "../../services/api";
import SideBar from './../SideBar';

const ResultadoRifa = () => {
    const [rifas, setRifas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detalheResultadoRifa, setDetalheResultadoRifa] = useState(false);
    const [resultRifa, setResultRifa] = useState(false);
    const [selectedRifa, setSelectedRifa] = useState(null);
    const [colocacao, setColocacao] = useState({
        id: '',
        nome: '',
        data: '',
        primeiro: '',
        segundo: '',
        terceiro: '',
        quarto: '',
        quinto: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setColocacao(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleShow = () => setDetalheResultadoRifa(true);
    const handleClose = () => setDetalheResultadoRifa(false);
    const handleShowSendResult = (rifa) => {
        console.log('Rifa selecionada:', rifa);
        setSelectedRifa(rifa);
        setColocacao(prevState => ({
            ...prevState,
            id: rifa.id, // Define o id da rifa
            nome: rifa.nome, // Define o nome da rifa
        }));
        setResultRifa(true);
    };

    const handleCloseSendResult = () => setResultRifa(false);


    useEffect(() => {
        loadRifas();
    }, []);

    const loadRifas = () => {
        setLoading(true);
        api.get(`/listRifas`)
            .then((response) => {
                setRifas(response.data.rifas);
                console.log(response.data.rifas)
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const closeButtonRef = useRef(null);

    const scrollEsquerda = () => {
        const container = closeButtonRef.current;
        container.scrollLeft -= 600; // ou qualquer valor que você preferir
    };

    const scrollDireita = () => {
        const container = closeButtonRef.current;
        container.scrollLeft += 600; // ou qualquer valor que você preferir
    };


    const sendResultRifa = async () => {
        console.log(colocacao)
        api.post(`/resultRifa`, colocacao)
            .then(() => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "success",
                    title: "Cotação cadastrada com sucesso"
                });
            })
            .catch((response) => {
                console.log(response);

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "warning",
                    title: "houve algum error!!"
                });
            })
            .finally(() => {
            });
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                {/* <ScaleLoader color={"#000"} loading={loading} /> */}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Rifas
                                        <span className="sub-header-quote">Resultados Rifas</span>



                                    </div>
                                    <main>
                                        <div className="d-flex justify-content-between mb-3">
                                            <button type="button" className="btn" id="btnScrollEsquerda" onClick={scrollEsquerda}>
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-back-circle-outline"></ion-icon>
                                            </button>

                                            <div className="loteria-container">
                                                {rifas.map((rifa) => (
                                                    <div id="card" className="loteria-card" key={rifa.r3_id}>
                                                        <div className="largura-card">
                                                            <h4 style={{ textAlign: 'center' }}>{rifa.descricao}</h4>

                                                            <h4>{rifa.descricao}</h4>
                                                            <div className="dias-semana">
                                                                <div className="dia">
                                                                    <div className="form-check-loteria">
                                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="button-container d-flex justify-content-between">
                                                                <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#editarCotacao"
                                                                    onClick={() => handleShowSendResult(rifa)}
                                                                >
                                                                    <i class="fa fa-upload"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-primary"
                                                                // onClick={() => deleteCotacao(cotacoe.c2_id)}
                                                                >
                                                                    <i class="fa fa-thin fa-info"></i>

                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" className="btn btn-lg " id="btnScrollDireita" onClick={scrollDireita}>
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-forward-circle-outline"></ion-icon>
                                            </button>
                                        </div>
                                        <Modal show={resultRifa} onHide={handleCloseSendResult} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>Resultado Rifa</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <div className="container">
                                                        {selectedRifa && (
                                                            <>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="nomeLoteria">Rifa: {selectedRifa.nome}</label>
                                                                    </div>

                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="descricaoLoteria">Descrição: {selectedRifa.descricao}</label>
                                                                    </div>
                                                                    <div className="header-loteria">
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="descricaoLoteria">Data:</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input type="date" name="data" value={colocacao.data} onChange={handleInputChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="descricaoLoteria">Primeiro:</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input type="text" name="primeiro" value={colocacao.primeiro} onChange={handleInputChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="descricaoLoteria">Segundo:</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input type="text" name="segundo" value={colocacao.segundo} onChange={handleInputChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="descricaoLoteria">Terceiro:</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input type="text" name="terceiro" value={colocacao.terceiro} onChange={handleInputChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="descricaoLoteria">Quarto:</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input type="text" name="quarto" value={colocacao.quarto} onChange={handleInputChange} />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 modal-label">
                                                                        <label htmlFor="descricaoLoteria">Quinto:</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <input type="text" name="quinto" value={colocacao.quinto} onChange={handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={() => selectedRifa && sendResultRifa(selectedRifa.id, colocacao.data, colocacao.primeiro, colocacao.segundo, colocacao.terceiro, colocacao.quarto, colocacao.quinto)}>
                                                    + CADASTRAR
                                                </Button>

                                            </Modal.Footer>
                                        </Modal>

                                        {/* <Modal show={detalheResultadoRifa} onHide={handleClose} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>EDITAR COTAÇÃO</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="modal fade" id="novaCotacao" tabIndex="-1" role="dialog" aria-labelledby="editarModalLabel" aria-hidden="true">
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={upCotacao}>
                                                    + SALVAR
                                                </Button>
                                            </Modal.Footer>
                                        </Modal> */}

                                        {/* Modal Editar Cotação */}
                                        <div className="modal fade" id="editarCotacao" tabIndex="-1" role="dialog" aria-labelledby="editarModalLabel" aria-hidden="true">
                                            {/* Modal content goes here */}
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ResultadoRifa;
// 341