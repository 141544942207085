import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import './Login.css'; // Importe o arquivo CSS que contém estilos

const Login = () => {
  const { login, errorMessage } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password, errorMessage);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-box">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="user-box">
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Login</label>
        </div>
        <div className="user-box">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label>Senha</label>
        </div>
        {error && <p className="error-message">{errorMessage}</p>}
        <button type="submit">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          LOGAR
        </button>
      </form>
    </div>
  );
};

export default Login;
