import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import api from "../../services/api";
import SideBar from './../SideBar';

const Descarga = () => {
    const [seuSwitch, setSeuSwitch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [descarga, setDescarga] = useState({});
    const [modalidades, setModalidades] = useState([]);

    const [modalNomeLoteria, setModalNomeLoteria] = useState('');
    const [modalSiglaLoteria, setModalSiglaLoteria] = useState('');
    const [valorUnico, setValorUnico] = useState('');

    const [modalNewDescarga, setModalNewDescarga] = useState(false);
    const [modalEditDescarga, setModalEditDescarga] = useState(false);

    const handleCloseCad = () => setModalNewDescarga(false);
    const handleCloseUp = () => setModalEditDescarga(false);
    const handleShowCad = () => setModalNewDescarga(true);
    const handleShowUp = () => setModalEditDescarga(true);


    useEffect(() => {
        loadModalidades();
    }, []);

    const loadModalidades = () => {
        api.get(`/listModalidades`)
            .then(response => {
                setModalidades(response.data.modalidades);
            })
            .catch(error => {
                console.log('deu errado');
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const sendDescarga = () => {
        // Remova os campos irrelevantes com base no valor de seuSwitch
        if (seuSwitch) {
            delete descarga.milhar;
            delete descarga.centena;
            delete descarga.dezena;
            delete descarga.unidade;
            delete descarga.grupo;
        } else {
            delete descarga.valorUnico;
        }

        descarga.valorSwitch = seuSwitch;

        setLoading(true);
        api.post(`/cadDescarga`, descarga)
            .then(response => {
                console.log('Dados enviados com sucesso:', response.data);
            })
            .catch(error => {
                console.error('Erro ao enviar descarga:', error);
            })
            .finally(() => {
                setLoading(false);
                setDescarga({});
            });
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Descarga <span className="sub-header-quote">Cadastros no Sistema</span>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShowCad}>
                                            + Nova Descarga
                                        </button>
                                    </div>
                                    <main>
                                        <div className="d-flex justify-content-between mb-3">
                                            <button type="button" className="btn" id="btnScrollEsquerda">
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-back-circle-outline"></ion-icon>
                                            </button>


                                            <button type="button" className="btn btn-sm" id="btnScrollDireita">
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-forward-circle-outline"></ion-icon>
                                            </button>
                                        </div>

                                        <Modal show={modalNewDescarga} onHide={handleCloseCad} size="lg">
                                            <Modal.Header closeButton>
                                                <Modal.Title>Nova Descarga</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>

                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                           
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="modalNomeLoteria" className="form-label">Nome</label>
                                                                    <input type="text" className="form-control" id="modalNomeLoteria" value={modalNomeLoteria} onChange={(e) => setModalNomeLoteria(e.target.value)} required />
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="modalSiglaLoteria" className="form-label me-2">Descrição</label>
                                                                    <input type="text" className="form-control" id="modalSiglaLoteria" value={modalSiglaLoteria} onChange={(e) => setModalSiglaLoteria(e.target.value)} required />
                                                                </div>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" id="switchInput" checked={seuSwitch} onChange={(e) => setSeuSwitch(e.target.checked)} />
                                                                    {seuSwitch ? <label className="form-check-label" htmlFor="switchInput">Detalhado</label> : <label className="form-check-label" htmlFor="switchInput">Geral</label>}
                                                                </div>
                                                                {seuSwitch && (
                                                                    <div>
                                                                        <label htmlFor="valorUnico">Valor Único:</label>
                                                                        <input type="text" id="valorUnico" className="form-control" value={valorUnico} onChange={(e) => setValorUnico(e.target.value)} />
                                                                    </div>
                                                                )}
                                                                {!seuSwitch && (
                                                                    <div>
                                                                        {/* Renderizar os campos para cada modalidade */}
                                                                        {modalidades.map(modalidade => (
                                                                            <div key={modalidade.id} className="form-floating mb-3">
                                                                                <input type="text" className="form-control" id={modalidade.id} placeholder="" value={descarga[modalidade.id]} onChange={(e) => setDescarga({ ...descarga, [modalidade.id]: e.target.value })} required />
                                                                                <label>{modalidade.nome}</label>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="modal-footer">
                                                                <div className="content">
                                                                    <div className="col-md-12">
                                                                        <button className="btn btn-success" onClick={() => sendDescarga(descarga)}><i className="fa fa-send-o"></i> Cadastrar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="primary" onClick={sendDescarga}>
                                                    + CADASTRAR
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        {/* Modal Editar Loteria */}
                                        {/* Conteúdo do modal aqui */}
                                        {/* Modal Nova Loteria */}
                                        {/* Conteúdo do modal aqui */}
                                    </main>
                                </div>
                                <div id="navbar-placeholder"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Descarga;