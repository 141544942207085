import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from "../../services/api";
import SideBar from './../SideBar';
import ScaleLoader from "react-spinners/ScaleLoader";

function Anuncios() {
    const [anuncios, setAnuncios] = useState([]);
    const [formData, setFormData] = useState({
        type: '',
        description: '',
        tipo_mod: '',
        url: '',
        temp: '',
        image: '',
    });
    const [showUrlField, setShowUrlField] = useState(false); 
    const [loading, setLoading] = useState(false);
    const [newRifa, setNewRifa] = useState(false);
    const handleShowCad = () => setNewRifa(true);
    const handleCloseCad = () => setNewRifa(false);

    const handleCheckboxChange = () => {
        setShowUrlField(!showUrlField);
        setFormData({ ...formData, url: '' }); // Limpa o campo de URL quando o checkbox é alterado
    };

    useEffect(() => {
        loadAnuncios();
    }, []);

    const loadAnuncios = () => {
        setLoading(true);
        api.get(`/listAnuncios`)
            .then((response) => {
                setAnuncios(response.data.anuncios);
            })
            .catch(error => {
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleImageChange = (event) => {
        const image = event.target.files[0];
        setFormData({ ...formData, image, name: image.name });
    };

    const submitRaffle = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await api.post(`/cadastroRifa`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            alert('Rifa cadastrada com sucesso!');
            setNewRifa(false)
        } catch (error) {
            console.error('Erro ao cadastrar rifa:', error);
            alert('Erro ao cadastrar rifa. Verifique o console para mais detalhes.');
        } finally {
            setLoading(false);
        }
    };

    const ativarDesativar = async (rifaId, ativo) => {
        try {
            await api.put(`/ativarDesativarRifa/${rifaId}`, { ativo });
            loadAnuncios()
        } catch (error) {
            // Lidar com erros
        }
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                {/* <ScaleLoader color={"#000"} loading={loading} /> */}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Anuncios
                                        <span className="sub-header-quote">Anuncios WEB</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShowCad}>
                                            + Novo Anuncio
                                        </button>
                                    </div>

                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <div className="box box-primary">
                                                <div className="content">
                                                    <div className="box-header with-border">
                                                        <div className="row">
                                                            <div className="box-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-hover">
                                                                        <thead className="tabela-class">
                                                                            <tr>
                                                                                <th className="tabela-class">NOME</th>
                                                                                <th className="tabela-class">DISPONIVEL</th>
                                                                                <th className="tabela-class">STATUS</th>
                                                                                <th className="tabela-class">DATA CRIADO</th>
                                                                                <th className="tabela-class">ABRIR/FECHAR</th>
                                                                                <th className="tabela-class">EDITAR</th>
                                                                                {/* <th className="tabela-class">DETALHAR</th> */}
                                                                                <th className="tabela-class">DELETAR</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="tabela-class">
                                                                            {anuncios.map((anuncio) => (
                                                                                <tr key={anuncio.id}>
                                                                                    {/* <td>{anuncio.nome}</td>
                                                                                    <td>{anuncio.disponivel}</td>
                                                                                    <td>{anuncio.aberto === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}</td>
                                                                                    <td>{new Date(anuncio.criado_em).toLocaleDateString('pt-BR')}</td> */}
                                                                                    {/* <td><button className="btn btn-primary" onClick={() => openEditModal(rifa)}><i className="fa fa-edit"></i></button></td> */}
                                                                                    {/* {anuncio.aberto === 1 ? <td><button className="btn btn-warning" onClick={() => ativarDesativar(anuncio.id, 0)}><i className="fa fa-times"></i></button></td>
                                                                                        : <td><button className="btn btn-success" onClick={() => ativarDesativar(anuncio.id, 1)}><i className="fa fa-check"></i></button></td>} */}
                                                                                    {/* <td><button className="btn btn-primary" data-bs-target="#editRifa"><i className="fa fa-edit"></i></button></td> */}
                                                                                    <td><button className="btn btn-danger"><i className="fa fa-trash"></i></button></td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>

                                                                    <ScaleLoader color={"#000"} loading={loading} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <Modal show={newRifa} onHide={handleCloseCad} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Novo Anuncio</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="modal-body">
                                                <div className="centralizar">
                                                    <div className="container">
                                                        <form onSubmit={submitRaffle} className="form">
                                                        <div className="row">
    <div className="col-md-6 mb-3">
        <div className="input-group">
            <div className="input-group-prepend">
                <label htmlFor="raffleType">Tipo de Anúncio:</label>
            <select id="raffleType" className="form-control" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })} required>
                <option value="">Selecione o tipo de anúncio</option>
                <option value="video">Vídeo</option>
                <option value="banner">Banner</option>
            </select>
            </div>
        </div>
    </div>
    <div className="col-md-6 mb-3">
        <div className="input-group">
            <div className="input-group-prepend">
                <label htmlFor="raffleType2">Modalidade:</label>
            <select id="raffleType2" className="form-control" value={formData.tipo_mod} onChange={(e) => setFormData({ ...formData, tipo_mod: e.target.value })} required>
                <option value="">Selecione a Modalidade</option>
                <option value="bingo">Bingo</option>
                <option value="web">Web</option>
            </select>
            </div>
        </div>
    </div>
</div>


                                                            <div className="form-group">
                                                                <label htmlFor="description">Descrição:</label>
                                                                <textarea id="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} className="form-control" required></textarea>
                                                            </div>

                                                            <div className="row">
                                                                        <span>Tempo por segundos</span>
                                                                    <div className="col-md-3 mb-5"> 
                                                                        <div className="input-group">
                                                                            <input type="number" value={formData.temp} onChange={(e) => setFormData({ ...formData, temp: e.target.value })} id="rankingP" className="form-control" required />
                                                                        </div>
                                                                    </div>
                                                            </div>

                                                            <div className="form-group form-check">
                                                                <input type="checkbox" className="form-check-input" id="showUrlField" checked={showUrlField} onChange={handleCheckboxChange} />
                                                                <label className="form-check-label" htmlFor="showUrlField">Adicionar url ou imagem</label>
                                                            </div>

                                                            {showUrlField ? (
                                                                <div className="form-group">
                                                                    <label htmlFor="url">URL:</label>
                                                                    <input type="text" id="url" value={formData.url} onChange={(e) => setFormData({ ...formData, url: e.target.value })} className="form-control" required />
                                                                </div>
                                                            ) : (
                                                              
                                                            <div className="form-group">
                                                                <label htmlFor="image">Imagem da Rifa:</label>
                                                                <input type="file" onChange={handleImageChange} className="custom-file-input" accept="image/*" required />
                                                            </div>
                                                            )}
                                                            {/* <button type="submit" className="button">Cadastrar Rifa</button> */}
                                                        </form>
                                                    </div>


                                                </div>
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={submitRaffle}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Anuncios;

