import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from './../../convert';
import './../styles/Global.css';
import SideBar from './../SideBar';
import Swal from 'sweetalert2';
import RingLoader from 'react-spinners/RingLoader';

const CnEquipamento = () => {
    const [loading, setLoading] = useState(false);
    const [leituristas, setLeituristas] = useState([]);
    const [gerentes, setGerentes] = useState([]);
    const [equipamento, setEquipamento] = useState([]);
    const [lojas, setLojas] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);


    const [cambistas, setCambistas] = useState([]);
    const [divAtiva, setDivAtiva] = useState(1);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [upCambista, setUpCambista] = useState(null);
    const [id, setId] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const handleEdit = (loja) => {
        setUpCambista(
            {
                codigo: loja.c3_codigo,
                cep: loja.c3_cep,
                login: loja.c3_login,
                senha: loja.senha,
                nomeEstabelecimento: loja.c3_nome_estabelecimento,
                nome: loja.c3_nome,
                cpf: loja.c3_cpf,
                nomePule: loja.c3_nome_pule,
                selectedGerente: loja.c3_id_gerente,
                selectedLeiturista: loja.c3_id_leiturista,
                endereco: loja.c3_endereco,
                telefone1: loja.c3_telefone_1,
                telefone2: loja.c3_telefone_2,
                cancelar: loja.c3_cancelar,
                comJb: loja.c3_jb,
                comRifa: loja.c3_rifa,
                comBingo: loja.c3_bingo,
                liberado: loja.c3_liberado,

            }
        )
        setId(loja.c3_id);
        handleShowEdit(); // Show the modal
    };

    const editCambista = () => {
        api.put(`/updateCambista/${id}`, { ...upCambista })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Cambista atualizado com sucesso',
                    timer: 7000,
                    timerProgressBar: true,
                });
                setShowEdit(false);
                // setUpCambista(null);
                loadLojas();
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'warning',
                    title: 'Houve algum erro!',
                    timer: 7000,
                    timerProgressBar: true,
                });
                // console.log(error);
            });
    };

    const deletCambista = (loja) => {
        const id = loja.c3_id;

        Swal.fire({
            title: `Tem certeza que deseja deletar o Cambista: ${loja.c3_nome}?`,
            text: "Esta ação não pode ser desfeita.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, deletar!"
        }).then((result) => {
            if (result.isConfirmed) {
                // Enviar requisição DELETE para deletar o cambista
                api.delete(`/deletCambista/${id}`)
                    .then(response => {
                        Swal.fire({
                            title: "Deletado!",
                            text: `${loja.c3_nome} foi deletado com sucesso.`,
                            icon: "success"
                        });
                        loadLojas();
                    })
                    .catch(error => {
                        Swal.fire({
                            title: "Erro!",
                            text: error.response.data.error || "Ocorreu um erro ao deletar o cambista.",
                            icon: "error"
                        });
                        // console.error(error);
                    });
            }
        });
    };

    useEffect(() => {
        loadLeituristas();
        loadGerentes();
        loadLojas();
        loadEquipamentos();
    }, []);

    const loadEquipamentos = () => {
        setLoading(true)
        api.get(`/listEquipamentos`)
            .then((response) => {
                setEquipamentos(response.data.equipamentos);
            })
            .catch((error) => {
                // console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const loadLojas = () => {
        setLoading(true)
        api.get(`/listLojas`)
            .then((response) => {
                setLojas(response.data.lojas);
            })
            .catch((error) => {
                // console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const loadGerentes = () => {
        api.get(`/listGerentes`)
            .then((response) => {
                setGerentes(response.data.gerentes);
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    const loadLeituristas = () => {
        api.get(`/listLeituristas`)
            .then((response) => {
                setLeituristas(response.data.leiturista);
            })
            .catch((error) => {
                // console.log(error);
            });
    };

    function sendEquipamento() {
        api.post(`/cadEquipamento`, { ...equipamento })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Equipamento cadastrada com sucesso',
                    timer: 7000,
                    timerProgressBar: true,
                });
                setShow(false)
                setEquipamento('')
                loadLeituristas();
        loadGerentes();
        loadLojas();
        loadEquipamentos();
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'warning',
                    title: 'Houve algum erro!',
                    timer: 7000,
                    timerProgressBar: true,
                });
            });
    }

    const renderizarDiv = (numero) => {
        setDivAtiva(numero);
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Equipamentos
                                        <span className="sub-header-quote">Cadastro de Equipamentos</span>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-primary new-quote-btn" onClick={handleShow}>
                                            + Nova Loja
                                        </button>
                                    </div>
                                    <div className="content">
                                        <table className="table table-bordered table-hover">
                                            <thead className="tabela-class">
                                                <tr>
                                                    <th className="tabela-class">CODIGO</th>
                                                    <th className="tabela-class">JOGO</th>
                                                    <th className="tabela-class">ENTRADA ATUAL</th>
                                                    <th className="tabela-class">ENTRADA ULT.</th>
                                                    <th className="tabela-class">SAIDA ATUAL</th>
                                                    <th className="tabela-class">SAIDA ULT.</th>
                                                    {/* <th className="tabela-class">ULT. MOVIMENTO</th> */}
                                                    <th className="tabela-class">EDITAR</th>
                                                    <th className="tabela-class">DELETAR</th>
                                                </tr>
                                            </thead>

                                            <tbody className="tabela-class">
                                                <>
                                                    {equipamentos.map(equipamento => (
                                                        <tr key={equipamento.le1_id}>
                                                            <td>{equipamento.le1_codigo}</td>
                                                            <td>{equipamento.le1_tj}</td>
                                                            <td>{equipamento.le2_entrada}</td>
                                                            <td>{equipamento.le2_ultima_entrada}</td>
                                                            <td>{equipamento.le2_saida}</td>
                                                            <td>{equipamento.le2_ultima_saida}</td>

                                                            {/* <td>{formatDate(equipamento.created_at)}</td> */}
                                                            <td><button className="btn btn-primary btn-large" >

                                                                <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(equipamento)} />
                                                            </button></td>
                                                            <td><button className="btn btn-danger btn-large">
                                                                <FontAwesomeIcon icon={faTrash} onClick={() => deletCambista(equipamento)} /></button></td>
                                                        </tr>
                                                    ))}
                                                </>
                                            </tbody>
                                        </table>
                                        < div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <RingLoader
                                                loading={loading}
                                                size={150}
                                                aria-label="Loading Spinner"
                                                data-testid="loader"
                                                css={{ margin: 'auto' }}
                                            />
                                        </div>
                                    </div>
                                    <Modal show={show} onHide={handleClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>CADASTRAR LOJA</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="centralizar">
                                                {/* <div id="app" className="button-container">

                                                    <button
                                                        className={`btn btn-primary new-quote-btn ${divAtiva === 1 ? 'active' : ''}`}
                                                        onClick={() => renderizarDiv(1)}
                                                    >
                                                        Associar
                                                    </button>
                                                </div> */}
                                            </div>

                                            {divAtiva === 1 && (
                                                <div className="div1">
                                                    <div className="row mb-3">
                                                        <div className="col-md-4">
                                                            <label htmlFor="nomeLoteria">Loja:</label>
                                                            <select
                                                                className="form-select form-select-lg mb-3"
                                                                aria-label="Large select example"
                                                                value={equipamento.id_loja}
                                                                onChange={(e) => setEquipamento({ ...equipamento, id_loja: e.target.value })}
                                                            > <option value={null} disabled selected>
                                                                    Selecione uma Loja
                                                                </option>
                                                                {lojas.map(loja => (
                                                                    <option key={loja.ll1_id} value={loja.ll1_id}>
                                                                        {loja.ll1_nome}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                        <label htmlFor="nomeLoteria">Credito:</label>   
                                                            <select
                                                                value={equipamento.credito}
                                                                onChange={(e) => setEquipamento({ ...equipamento, credito: e.target.value })}
                                                                className="form-select form-select-lg mb-3"
                                                                aria-label="Default select example"
                                                            >
                                                                <option selected disabled value="">
                                                                    Tipo de crédito
                                                                </option>
                                                                <option value="0.01">0.01</option>
                                                                <option value="0.10">0.10</option>
                                                                <option value="1.00">1.00</option>
                                                                <option value="1.50">1.50</option>
                                                                <option value="2.00">2.00</option>
                                                            </select>
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <label htmlFor="nomeLoteria">Leiturista:</label>
                                                            <select className="form-select form-select-lg mb-3" aria-label="Large select example" value={loja.selectedLeiturista}
                                                                onChange={(e) => setEquipamento({ ...equipamento, selectedLeiturista: e.target.value })}>
                                                                <option value={null} disabled selected>
                                                                    Selecione um leiturista
                                                                </option>
                                                                {leituristas.map(leiturista => (
                                                                    <option key={leiturista.u1_id} value={leiturista.u1_id}>
                                                                        {leiturista.u1_nome}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div> */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Codigo</label>
                                                                <input type="text" className="form-control" placeholder="Insira o código" value={equipamento.codigo} onChange={(e) => setEquipamento({ ...equipamento, codigo: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Tipo de jogo</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome do jogo" value={equipamento.tj} onChange={(e) => setEquipamento({ ...equipamento, tj: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Entrada</label>
                                                                <input type="text" className="form-control" placeholder="Entrada inicial" value={equipamento.entrada} onChange={(e) => setEquipamento({ ...equipamento, entrada: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Saida</label>
                                                                <input type="text" className="form-control" placeholder="Saida inicial" value={equipamento.saida} onChange={(e) => setEquipamento({ ...equipamento, saida: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={sendEquipamento}>
                                                + CADASTRAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    {/* <Modal show={showEdit} onHide={handleCloseEdit} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>EDITAR LOJA</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="centralizar">
                                                <div id="app" className="button-container">
                                                    <button
                                                        className={`btn btn-primary new-quote-btn ${divAtiva === 4 ? 'active' : ''}`}
                                                        onClick={() => renderizarDiv(4)}
                                                    >
                                                        Associar
                                                    </button>
                                                    <button
                                                        className={`btn btn-primary new-quote-btn ${divAtiva === 5 ? 'active' : ''}`}
                                                        onClick={() => renderizarDiv(5)}
                                                    >
                                                        Comissões
                                                    </button>
                                                    <button
                                                        className={`btn btn-primary new-quote-btn ${divAtiva === 6 ? 'active' : ''}`}
                                                        onClick={() => renderizarDiv(6)}
                                                    >
                                                        Dados
                                                    </button>
                                                </div>
                                            </div>

                                            {divAtiva === 4 && (
                                                <div className="div1">
                                                    <div className="row mb-3">
                                                        <div className="col-md-4">
                                                            <label htmlFor="nomeLoteria">Gerente:</label>
                                                            <select className="form-select form-select-lg mb-3" aria-label="Large select example" value={upCambista.selectedGerente} onChange={(e) => setUpCambista({ ...upCambista, selectedGerente: e.target.value })}>
                                                                <option value={null} disabled>
                                                                    Selecione um gerente
                                                                </option>
                                                                {gerentes.map(gerente => (
                                                                    <option key={gerente.u1_id} value={gerente.u1_id}>
                                                                        {gerente.u1_nome}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label htmlFor="nomeLoteria">Leiturista:</label>
                                                            <select className="form-select form-select-lg mb-3" aria-label="Large select example" value={upCambista.selectedLeiturista} onChange={(e) => setUpCambista({ ...upCambista, selectedLeiturista: e.target.value })} selectedLeiturista>
                                                                <option value={null} disabled>
                                                                    Selecione um leiturista
                                                                </option>
                                                                {leituristas.map(leiturista => (
                                                                    <option key={leiturista.u1_id} value={leiturista.u1_id}>
                                                                        {leiturista.u1_nome}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Codigo</label>
                                                                <input type="text" className="form-control" placeholder="Insira o código" value={upCambista.codigo} onChange={(e) => setUpCambista({ ...upCambista, codigo: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Nome Pule</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome da pule" value={upCambista.nomePule} onChange={(e) => setUpCambista({ ...upCambista, nomePule: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Login</label>
                                                                <input type="text" className="form-control" placeholder="Insira o login" value={upCambista.login} onChange={(e) => setUpCambista({ ...upCambista, login: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Senha</label>
                                                                <input type="text" className="form-control" placeholder="Insira a senha" value={upCambista.senha} onChange={(e) => setUpCambista({ ...upCambista, senha: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Tempo para Cancelar</label>
                                                                <input type="number" className="form-control" placeholder="Insira o tempo de cancelamento" value={upCambista ? upCambista.cancelar : ''} onChange={(e) => setUpCambista({ ...upCambista, cancelarsetUpCambista: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Situação</label><br />
                                                                <input type="radio" className="btn-check" name="options-outlined" id="success-outlined" autoComplete="off" checked />
                                                                <label className="btn btn-outline-success" htmlFor="success-outlined">Ativo</label>
                                                                <input type="radio" className="btn-check" id="btn-check-outlined" name="options-outlined" autoComplete="off" />
                                                                <label className="btn btn-outline-primary" htmlFor="btn-check-outlined">Teste</label>
                                                                <input type="radio" className="btn-check" name="options-outlined" id="danger-outlined" autoComplete="off" />
                                                                <label className="btn btn-outline-danger" htmlFor="danger-outlined">Bloq.</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {divAtiva === 5 && (
                                                <div className="div2">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">J.B</label>
                                                                <input type="number" className="form-control" placeholder="Insira a comissão do J.B" value={upCambista.comJb} onChange={(e) => setUpCambista({ ...upCambista, comJb: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Rifa</label>
                                                                <input type="number" className="form-control" placeholder="Insira a comissão da Rifa" value={upCambista.comRifa} onChange={(e) => setUpCambista({ ...upCambista, comRifa: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Bingo</label>
                                                                <input type="number" className="form-control" placeholder="Insira a comissão do bingo" value={upCambista.comBingo} onChange={(e) => setUpCambista({ ...upCambista, comBingo: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comJb" checked={upCambista.liberadoJb} onChange={(e) => setUpCambista({ ...upCambista, liberadoJb: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comJb">J.B</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comRifa" checked={upCambista.liberadoRifa} onChange={(e) => setUpCambista({ ...upCambista, liberadoRifa: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comRifa">Rifa</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comBingo" checked={upCambista.liberadoBingo} onChange={(e) => setUpCambista({ ...upCambista, liberadoBingo: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comBingo">Bingo</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comBolao" checked={upCambista.liberadoBolao} onChange={(e) => setUpCambista({ ...upCambista, liberadoBolao: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comBolao">Bolão</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comSaque" checked={upCambista.liberadoSaque} onChange={(e) => setUpCambista({ ...upCambista, liberadoSaque: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comSaque">Saque</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" id="comDeposito" checked={upCambista.liberadoDeposito} onChange={(e) => setUpCambista({ ...upCambista, liberadoDeposito: e.target.checked })} />
                                                                        <label className="form-check-label" htmlFor="comDeposito">Depósito</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {divAtiva === 6 && (
                                                <div className="row mb-3">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Nome</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={upCambista.nome} onChange={(e) => setUpCambista({ ...upCambista, nome: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">CPF</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={upCambista.cpf} onChange={(e) => setUpCambista({ ...upCambista, cpf: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Nome Estabelecimento</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={upCambista.nomeEstabelecimento} onChange={(e) => setUpCambista({ ...upCambista, nomeEstabelecimento: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Endereço</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={upCambista.endereco} onChange={(e) => setUpCambista({ ...upCambista, endereco: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Telefone 1</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={upCambista.telefone1} onChange={(e) => setUpCambista({ ...upCambista, telefone1: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">Telefone 2</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={upCambista.telefone2} onChange={(e) => setUpCambista({ ...upCambista, telefone2: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1">CEP</label>
                                                                <input type="text" className="form-control" placeholder="Insira o nome" value={upCambista.cep} onChange={(e) => setUpCambista({ ...upCambista, cep: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}



                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={editCambista}>
                                                + EDITAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CnEquipamento;