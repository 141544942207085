import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import api from "../../services/api";
import SideBar from './../SideBar';

const Loteria = () => {
    const [loterias, setLoterias] = useState([]);
    const [loteria, setLoteria] = useState({
        nome: '',
        sigla: '',
        colocacao: '',
        encerramento: '',
        dom: false,
        seg: false,
        ter: false,
        qua: false,
        qui: false,
        sex: false,
        sab: false
    });
    const [modalNewLot, setModalNewLot] = useState(false);
    const [modalEditLot, setModalEditLot] = useState(false);
    const [upLoteria, setUpLoteria] = useState([]);
    const [id, setId] = useState('');


    const handleCloseCad = () => setModalNewLot(false);
    const handleCloseUp = () => setModalEditLot(false);
    const handleShowCad = () => setModalNewLot(true);
    const handleShowUp = () => setModalEditLot(true);

    useEffect(() => {
        loadLoterias();
    }, []);

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        setLoteria({ ...loteria, [name]: newValue });
    };

    const sendLoteria = () => {
        console.table(loteria)
        api.post(`/cadLoterias`, { ...loteria })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Loteria cadastrada com sucesso',
                    timer: 7000,
                    timerProgressBar: true,
                });
                setModalNewLot(false)
                setLoteria('')
                loadLoterias()
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'warning',
                    title: 'Houve algum erro!',
                    timer: 7000,
                    timerProgressBar: true,
                });
                // console.log(error);
            });
    };

    const handleEdit = (loteria) => {
        setUpLoteria(
           { 
            nome: loteria.l1_nome,
            sigla: loteria.l1_sigla,
            colocacao: loteria.l1_quantidade,
            encerramento: loteria.l1_enc_horario,
            dom: loteria.l1_dom,
            seg: loteria.l1_seg,
            ter: loteria.l1_ter,
            qua: loteria.l1_qua,
            qui: loteria.l1_qui,
            sex: loteria.l1_sex,
            sab: loteria.l1_sab,
        }
        )
        setId(loteria.l1_id)
        console.table(id)
        setModalEditLot(true)
    };

    const handleDiaSemanaChange = (e, dia) => {
        const isChecked = e.target.checked;
        setUpLoteria((prevLoteria) => ({
            ...prevLoteria,
            [dia]: isChecked
        }));
    };

    const editLoteria = () => {
        console.table(upLoteria)
        api.put(`/updateLoteria/${id}`, { ...upLoteria })
        .then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Loteria atualizada com sucesso',
                timer: 7000,
                timerProgressBar: true,
            });
            setModalEditLot(false);
            loadLoterias();
        })
        .catch((error) => {
            Swal.fire({
                icon: 'warning',
                title: 'Houve algum erro!',
                timer: 7000,
                timerProgressBar: true,
            });
            // console.log(error);
        });
    }

    const deletLoteria = (loteria) => {
        const id = loteria.l1_id;
    
        Swal.fire({
            title: `Tem certeza que deseja deletar a loteria: ${loteria.l1_nome}?`,
            text: "Esta ação não pode ser desfeita.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, deletar!"
        }).then((result) => {
            if (result.isConfirmed) {
                api.delete(`/deletLoteria/${id}`)
                    .then(response => {
                        Swal.fire({
                            title: "Deletado!",
                            text: `${loteria.l1_nome} foi deletado com sucesso.`,
                            icon: "success"
                        });
                        loadLoterias();
                    })
                    .catch(error => {
                        Swal.fire({
                            title: "Erro!",
                            text: error.response.data.error || "Ocorreu um erro ao deletar a loteria.",
                            icon: "error"
                        });
                        // console.error(error);
                    });
            }
        });
    };

    const loadLoterias = () => {
        api.get(`/listLoterias`)
            .then((response) => {
                setLoterias(response.data.loterias);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                // setFormulario(true);
            });
    };

    const scrollEsquerda = () => {
        // Implemente a lógica de scroll esquerda aqui
    };

    const scrollDireita = () => {
        // Implemente a lógica de scroll direita aqui
    };


    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Loterias <span className="sub-header-quote">Cadastros no Sistema</span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-dark new-quote-btn" onClick={handleShowCad}>
                                            + Nova Loteria
                                        </button>
                                    </div>
                                    <main>
                                        <div className="d-flex justify-content-between mb-3">
                                            <button type="button" className="btn" id="btnScrollEsquerda" onClick={scrollEsquerda}>
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-back-circle-outline"></ion-icon>
                                            </button>
                                            <div
                                                className="loteria-container"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    overflowX: 'auto'
                                                }}
                                            >
                                                {loterias.map((loteria) => (
                                                    <div id="card" className="loteria-card" key={loteria.l1_id} >
                                                        <div className="largura-card">
                                                            <h3>{loteria.l1_nome}</h3>
                                                            <h4>SIGLA: {loteria.l1_sigla}</h4>
                                                            <div className="center-dia">
                                                                <div className="diasSemana">
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck1"></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck2">
                                                                                {loteria.l1_dom === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}
                                                                            </label>
                                                                            <label className="form-check-label" htmlFor="defaultCheck2" style={{ fontSize: '130%', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                                                Domingo
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck2">
                                                                                {loteria.l1_seg === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}
                                                                            </label>
                                                                            <label className="form-check-label" htmlFor="defaultCheck2" style={{ fontSize: '130%', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                                                Segunda
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck3">
                                                                                {loteria.l1_ter === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}
                                                                            </label>
                                                                            <label className="form-check-label" htmlFor="defaultCheck3" style={{ fontSize: '130%', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                                                Terça
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck4">
                                                                                {loteria.l1_qua === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}
                                                                            </label>
                                                                            <label className="form-check-label" htmlFor="defaultCheck4" style={{ fontSize: '130%', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                                                Quarta
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck5">
                                                                                {loteria.l1_qui === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}
                                                                            </label>
                                                                            <label className="form-check-label" htmlFor="defaultCheck5" style={{ fontSize: '130%', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                                                Quinta
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck6">
                                                                                {loteria.l1_sex === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}
                                                                            </label>
                                                                            <label className="form-check-label" htmlFor="defaultCheck6" style={{ fontSize: '130%', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                                                Sexta
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="dia">
                                                                        <div className="form-check-loteria">
                                                                            <label className="form-check-label" htmlFor="defaultCheck7">
                                                                                {loteria.l1_sab === 1 ? <i className="fa fa-check ativo"></i> : <i className="fa fa-times bloqueado"></i>}
                                                                            </label>
                                                                            <label className="form-check-label" htmlFor="defaultCheck7" style={{ fontSize: '130%', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                                                Sábado
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="d-flex justify-content-between mb-3">
                                                                <button className="btn btn-primary"
                                                                onClick={() => handleEdit(loteria)}
                                                                >
                                                                    <FontAwesomeIcon size='2x' icon={faEdit} />
                                                                </button>
                                                                   
                                                                <button className="btn btn-danger"  onClick={() => deletLoteria(loteria)}>
                                                                    <FontAwesomeIcon size='2x' icon={faTrash} />
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" className="btn btn-sm" id="btnScrollDireita" onClick={scrollDireita}>
                                                <ion-icon style={{ fontSize: '30px' }} name="arrow-forward-circle-outline"></ion-icon>
                                            </button>

                                        </div>
                                    </main>
                                    <Modal show={modalNewLot} onHide={handleCloseCad} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>NOVA LOTERIA</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label htmlFor="modalNomeLoteria" className="form-label">Nome da Loteria</label>
                                                    <input type="text" className="form-control" id="modalNomeLoteria" name="nome" value={loteria.nome} onChange={handleInputChange} required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="modalSiglaLoteria" className="form-label">Sigla da Loteria</label>
                                                    <input type="text" className="form-control" id="modalSiglaLoteria" name="sigla" value={loteria.sigla} onChange={handleInputChange} required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="modalColocacao" className="form-label">Quant. de Colocação</label>
                                                    <select className="form-control" id="modalColocacao" name="colocacao" value={loteria.colocacao} onChange={handleInputChange} required>
                                                        <option value="" disabled selected>Selecione a quantidade de coloções</option>
                                                        <option value="7">7</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="encerramento" className="form-label">Encerramento</label>
                                                    <input type="time" className="form-control" id="encerramento" name="encerramento" value={loteria.encerramento} onChange={handleInputChange} required />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Dias da Semana</label>
                                                    {['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'].map((dia) => (
                                                        <div key={dia} className="form-check">
                                                            <input className="form-check-input" type="checkbox" id={dia} name={dia} checked={loteria[dia]} onChange={handleInputChange} />
                                                            <label className="form-check-label" htmlFor={dia}>{dia.charAt(0).toUpperCase() + dia.slice(1)}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" onClick={sendLoteria}>
                                                + SALVAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal show={modalEditLot} onHide={handleCloseUp}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>EDITAR COTAÇÃO</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                        <div className="modal-body">
                                                <div className="mb-3">
                                                    <label htmlFor="modalNomeLoteria" className="form-label">Nome da Loteria</label>
                                                    <input type="text" className="form-control" id="modalNomeLoteria" name="nome" value={upLoteria.nome} onChange={(e) => setUpLoteria({ ...upLoteria, nome: e.target.value })} required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="modalSiglaLoteria" className="form-label">Sigla da Loteria</label>
                                                    <input type="text" className="form-control" id="modalSiglaLoteria" name="sigla" value={upLoteria.sigla} onChange={(e) => setUpLoteria({ ...upLoteria, sigla: e.target.value })} required />
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="modalColocacao" className="form-label">Quant. de Colocação</label>
                                                    <select className="form-control" id="modalColocacao" name="colocacao" value={upLoteria.colocacao} onChange={(e) => setUpLoteria({ ...upLoteria, colocacao: e.target.value })} required>
                                                        <option value="" disabled selected>Selecione a quantidade de coloções</option>
                                                        <option value="7">7</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="encerramento" className="form-label">Encerramento</label>
                                                    <input type="time" className="form-control" id="encerramento" name="encerramento" value={upLoteria.encerramento} onChange={(e) => setUpLoteria({ ...upLoteria, encerramento: e.target.value })} required />
                                                </div>
                                                <div className="mb-3">
                <label className="form-label">Dias da Semana</label>
                {['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'].map((dia) => (
                    <div key={dia} className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={dia}
                            name={dia}
                            checked={upLoteria[dia]}
                            onChange={(e) => handleDiaSemanaChange(e, dia)}
                        />
                        <label className="form-check-label" htmlFor={dia}>
                            {dia.charAt(0).toUpperCase() + dia.slice(1)}
                        </label>
                    </div>
                ))}
            </div>
                                            </div>


                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary"
                                            onClick={editLoteria}
                                            >
                                                + SALVAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    centerDia: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    diasSemana: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'center'
    },

    dia: {
        marginBottom: '10px',
    },

};
export default Loteria;

