import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEdit, faTrash, faRefresh } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import api from "../../services/api";
import SideBar from './../SideBar';
import RingLoader from 'react-spinners/RingLoader';



const CaixaFinanceiro = () => {
    const [gerentes, setGerentes] = useState([]);
    const [leituristas, setLeituristas] = useState([]);
    const [modalLancamentoUser, setModalLancamentoUser] = useState({});
    const [lancamento, setLancamento] = useState({});
    const [modalLancar, setModalLancar] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setModalLancar(false);

    useEffect(() => {
        loadGerentes();
        loadLeituristas();
    }, []);

    const loadGerentes = () => {
        setLoading(true)
        api.get(`/listGerentes`)
            .then((response) => {
                setGerentes(response.data.gerentes);
            })
            .catch(error => {
                console.log('deu errado');
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const loadLeituristas = () => {
        api.get(`/listLeituristas`)
            .then((response) => {
                setLeituristas(response.data.leiturista);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const openModal = (usuario) => {
        setModalLancamentoUser(usuario);
        setModalLancar(true);
    };

    const closeModal = () => {
        setModalLancar(false);
    };

    const lancarCaixa = () => {
        if (!lancamento.tipo) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Selecione um tipo de lançamento!',
            });
            return;
        }
        api.post(`/caixa/`, {
            id: modalLancamentoUser.u1_id,
            tipo: lancamento.tipo,
            valor: lancamento.valor,
        })
            .then(response => {
                Swal.fire({
                    title: 'Lançamento bem-sucedido!',
                    icon: 'success',
                });
            })
            .catch(error => {
                Swal.fire({
                    title: 'Erro!',
                    text: 'Ocorreu um erro durante o lançamento.',
                    icon: 'error',
                });
                console.error(error);
            })
            .finally(() => {
                loadGerentes();
                loadLeituristas();
                setModalLancar(false);
            });
    };

    const zerarCaixa = (usuario) => {
        Swal.fire({
            title: `${usuario.u1_nome} - ${usuario.u1_caixa}`,
            text: `Zerar caixa do colaborador ${usuario.u1_nome}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, Zerar o caixa!"
        }).then((result) => {
            if (result.isConfirmed) {
                api.post(`/caixa/`, {
                    id: usuario.u1_id,
                    tipo: 'zerar',
                    valor: usuario.u1_caixa,
                })
                    .then(response => {
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success"
                        });
                    })
                    .catch(error => {
                        Swal.fire({
                            title: "Error!",
                            text: "An error occurred while deleting.",
                            icon: "error"
                        });
                        console.error(error);
                    })
                    .finally(() => {
                        loadGerentes();
                        loadLeituristas();
                    });
            }
        });
    };

    return (
        <div className="container1">
            <SideBar />
            <div className="content">
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="container mt-4">
                                    <div className="header-loteria">
                                        <i className="fas fa-ticket-alt"></i> Caixa
                                        <span className="sub-header-quote">Financeiro Firma/Colaborador</span>
                                    </div>
                                        <div>
                                            <div className="box box-primary">
                                                <div className="content">
                                                    <div className="box-header with-border">
                                                        <div className="row">
                                                            <div className="box-body">
                                                                <div className="table-responsive">
                                                                    <table className="table table-bordered table-hover">
                                                                        <thead className="tabela-class">
                                                                            <tr>
                                                                                <th className="tabela-class">TIPO</th>
                                                                                <th className="tabela-class">NOME</th>
                                                                                <th className="tabela-class">CAIXA</th>
                                                                                {/* <th className="tabela-class">RECOLHA DO DIA</th> */}
                                                                                {/* <th className="tabela-class">MOVIMENTO DO DIA</th> */}
                                                                                <th className="tabela-class">LANÇAR</th>
                                                                                <th className="tabela-class">ZERAR</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody className="tabela-class">
                                                                            {gerentes.map((gerente) => (
                                                                                <tr key={gerente.u1_id}>
                                                                                    <td>{'GERENTE'}</td>
                                                                                    <td>{gerente.u1_nome}</td>
                                                                                    <td>R${gerente.u1_caixa}</td>
                                                                                    {/* <td>{'10 mudar esse valor'}</td> */}
                                                                                    {/* <td>{134}</td> */}
                                                                                    <td>
                                                                                        <button className="btn btn-primary" onClick={() => openModal(gerente)}>
                                                                                            <i className="fa fa-edit"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="btn btn-danger" onClick={() => zerarCaixa(gerente)}>
                                                                                            <i className="fa fa-refresh fa-fw"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                            {leituristas.map((leiturista) => (
                                                                                <tr key={leiturista.u1_id}>
                                                                                    <td>{'LEITURISTA'}</td>
                                                                                    <td>{leiturista.u1_nome}</td>
                                                                                    <td>R${leiturista.u1_caixa}</td>
                                                                                    {/* <td>{'10 mudar esse valor'}</td> */}
                                                                                    {/* <td>{3444}</td> */}
                                                                                    <td>
                                                                                    <button className="btn btn-primary" onClick={() => openModal(leiturista)}>
                                                                                            <i className="fa fa-edit"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="btn btn-danger" onClick={() => zerarCaixa(leiturista)}>
                                                                                            <i className="fa fa-refresh fa-fw"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    < div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <RingLoader
                                                                        loading={loading}
                                                                        size={150}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                        css={{ margin: 'auto' }}
                                                                    />

                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                    <Modal show={modalLancar} onHide={handleClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Lançamentos Colaborador</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className="modal-body">
                                                <b-modal v-model="modalLancar" title="Detalhes do Gerente">
                                                    <p>Nome: {modalLancamentoUser.u1_nome}</p>
                                                    <p>Caixa: {modalLancamentoUser.u1_caixa}</p>
                                                    <select className="form-control" value={lancamento.tipo} onChange={(e) => setLancamento({ ...lancamento, tipo: e.target.value })}>
                                                        <option disabled selected value="">selecione o tipo</option>

                                                        <option value="recolher">Recolher</option>
                                                        <option value="pagar">Pagar</option>
                                                    </select>
                                                    <label htmlFor="modalValorLancamento" className="form-label">Valor a ser lançado</label>
                                                    <input className="form-control" id="modalValorLancamento" value={lancamento.valor} onChange={(e) => setLancamento({ ...lancamento, valor: e.target.value })} />
                                                </b-modal>
                                            </div>

                                        </Modal.Body >
                                        <Modal.Footer>
                                            {/* <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button> */}
                                            <Button variant="primary" onClick={lancarCaixa}>
                                                + LANÇAR
                                            </Button>
                                        </Modal.Footer>
                                    </Modal >





                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaixaFinanceiro;
//276